import React from 'react';
import { Typography } from '@mui/material';
import CustomIcon from './../../../../icons/customIcon';
import Treatment from '../../../Treatment';
import { featureFlags } from '../../../../splitSetup';
import { useAccount } from '../../../../contexts/Account';

const classes = {
  mainConatiner: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
  },
  shrinkControlContainer: {
    flexShrink: 0,
  },
  button: {
    border: 'none',
    display: 'flex',
    outline: 'none',
    backgroundColor: 'transparent',
    flexShrink: 0,
    padding: '0px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
    '&:disabled': {
      cursor: 'default',
    },
  },
  answersTextLarge: {
    marginTop: '4px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    color: '#232b35',
  },
  answersTextMobile: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    color: '#232b35',
    fontSize: '0.7rem',
  },
};

function AnswersComponent(props) {
  const { numAnswers, onClick, filled, disabled, iconSize } = props;
  const { currentUserInfo } = useAccount();
  const unsetNumber =
    numAnswers === null || numAnswers === undefined || isNaN(numAnswers);

  function renderIcon() {
    if (filled) {
      return (
        <CustomIcon
          iconname="comments"
          style={{
            color: '#1663F7',
            fontSize: sizeSelector(),
            marginRight: '8px',
            marginTop: -1,
          }}
        />
      );
    }
    return (
      <CustomIcon
        iconname="comments"
        style={{
          color: '#232B35',
          fontSize: sizeSelector(),
          marginRight: '8px',
          marginTop: -1,
        }}
      />
    );
  }

  function renderAnswers(text) {
    if (unsetNumber && iconSize === 'xs') {
      return (
        <Typography variant="caption" sx={classes.answersTextMobile}>
          0 {text}
        </Typography>
      );
    }
    if (unsetNumber) {
      return (
        <Typography variant="body1" sx={classes.answersTextLarge}>
          0 {text}
        </Typography>
      );
    }
    if (!unsetNumber && iconSize === 'xs') {
      return (
        <Typography variant="caption" sx={classes.answersTextMobile}>
          {`${numAnswers} ${text}`}
        </Typography>
      );
    }
    if (!unsetNumber) {
      return (
        <Typography variant="body1" sx={classes.answersTextLarge}>
          {`${numAnswers} ${text}`}
        </Typography>
      );
    }
    return null;
  }

  function sizeSelector() {
    if (iconSize === 'xs') {
      return 19;
    }
    if (iconSize === 'sm') {
      return 24;
    }
    if (iconSize === 'lg') {
      return 28;
    }
    return 19;
  }

  return (
    <div style={classes.mainConatiner}>
      <div style={classes.shrinkControlContainer}>
        <Treatment
          userId={
            currentUserInfo && currentUserInfo.username
              ? currentUserInfo.username
              : 'not_id'
          }
          splitNames={featureFlags.stop_gap_b2b_redesign}
          updateOnSdkTimedout
          evaluatedComponent={
            <button
              style={classes.button}
              onClick={(event) => {
                onClick();
                event.stopPropagation();
              }}
              id="answersButton"
              type="button"
              disabled={disabled}
            >
              {renderIcon()}
              {renderAnswers('Insights')}
            </button>
          }
          conditionFlag
          conditionFailedComponent={
            <button
              style={classes.button}
              onClick={(event) => {
                onClick();
                event.stopPropagation();
              }}
              id="answersButton"
              type="button"
              disabled={disabled}
            >
              {renderIcon()}
              {renderAnswers('Answers')}
            </button>
          }
        />
      </div>
    </div>
  );
}

export default AnswersComponent;
