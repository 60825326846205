import React from 'react';
import { Paper, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Building1 } from '../../icons/building_1.svg';

const getClasses = ({ isSM, backgroundColor = '#D7F1FD', shrinked }) => ({
  tile: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: isSM ? '100%' : '49%',
    height: '238px',
    borderRadius: '16px',
    padding: '8px',
    marginBottom: isSM ? '10px' : '',
    cursor: 'pointer',
    backgroundColor,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: isSM ? '0 15px' : '15px',
    height: '100%',
    textAlign: 'left',
  },
  tileTitle: {
    fontWeight: 600,
    fontSize: '24px',
    fontFamily: 'Poppins',
  },
  windowIconContainer: {
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    lineHeight: 0,
  },
});

function FeaturedBoardTile(props) {
  const { backgroundColor, shrinked } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses({ isSM, backgroundColor, shrinked });

  return (
    <Paper
      elevation={0}
      sx={classes.tile}
      id="featuredBoardTile"
      onClick={() => {}}
    >
      <div style={classes.titleContainer}>
        <Typography style={classes.tileTitle}>
          Welcome to the largest collection of company advisory boards,
          available exclusively on AdvisoryCloud.
        </Typography>
      </div>
      <div style={classes.windowIconContainer}>
        <Building1 />
      </div>
    </Paper>
  );
}
export default FeaturedBoardTile;
