import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import EmptyQuestionSvg from '../../../images/empty-question.svg';
import { companyFetchPostsCall } from '../../../services/company_services';
import SceneContentStateSelector from '../../../components/SceneContentStateSelector';
import FilteredFeed from '../../../components/FilteredFeed';
import { AccountType } from '../../../services/utils/types';
import { useAccount } from '../../../contexts/Account';

function Meetings({ company }) {
  const { t } = useTranslation();
  const { accountDetails } = useAccount();
  const imageArray = [
    {
      src: EmptyQuestionSvg,
      alt: 'empty-question.svg',
      width: '200',
    },
  ];

  const { companyName } = company;
  const postRenderedSelector = {
    multiplePost: {
      parameters: {
        ACCOUNT_TYPE: AccountType.ADVISOR,
        ADVISOR_ID: accountDetails.id,
        COMPANY_ID: company.id,
        LIMIT: 50,
        OFFSET: 0,
        ORDER_BY: 'createdAt',
        REVERSE: false,
        TYPE: 'boardMeetings',
      },
      asyncName: 'getPosts',
      graphqlEndpoint: companyFetchPostsCall,
      contentTag: FilteredFeed,
    },
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 'calc(100vw - 50px)',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 'auto',
          overflowX: 'scroll',
        }}
      >
        <SceneContentStateSelector
          helmetTitle={t('ADVISOR-BOARDROOMS-QUESTIONS-HELMET-TITLE')}
          endPointAppSyncName={postRenderedSelector.multiplePost.asyncName}
          endPointCall={postRenderedSelector.multiplePost.graphqlEndpoint}
          endPointCallParameters={postRenderedSelector.multiplePost.parameters}
          ContentTag={postRenderedSelector.multiplePost.contentTag}
          contentProps={{
            asyncName: postRenderedSelector.multiplePost.asyncName,
          }}
          noMaxWidth
          emptyStateProps={{
            imageArray,
            titleText: t('ADVISOR_BOARDROOMS_MEETINGS_EMPTY_STATE_TITLE', {
              companyName,
            }),
            contentText: t('ADVISOR_BOARDROOMS_MEETINGS_EMPTY_STATE_CONTENT', {
              companyName,
            }),
          }}
        />
      </Box>
    </Box>
  );
}

export default Meetings;
