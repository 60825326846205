import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery } from '@mui/material';
import AdvisoryCloudLogo from '../components/AdvisoryCloudLogo';
import AOBFForm from '../components/Advisor/AOBF/Form';

const useStyles = (isSM) => ({
  main: {
    with: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    padding: isSM ? '16px 8px' : '16px 120px 0',
    justifyContent: isSM ? 'center' : null,
    width: '100%',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '600px',
  },
  title: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
    marginBottom: '16px',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
    color: '#646D7A',
    maxWidth: '360px',
    textAlign: 'center',
  },
});

function AOBF() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('ONBOARDING-HELMET-TITLE')}</title>
      </Helmet>

      <Box sx={classes.main}>
        <Box sx={classes.header}>
          <AdvisoryCloudLogo
            removeHyperlink
            onClick={() => {}}
            width={isSM ? 240 : 180}
            height={isSM ? 80 :60}
            cursor="default"
          />
        </Box>
        <Box sx={classes.body}>
          <Typography sx={classes.title}>One last step...</Typography>
          <Typography sx={classes.subtitle}>
            Please answer a few questions right now so we can get you started
            joining boards.
          </Typography>
          <AOBFForm />
        </Box>
      </Box>
    </>
  );
}

export default AOBF;
