import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paper, Typography, useMediaQuery, Box } from '@mui/material';
import { AddCircle, EditOutlined } from '@mui/icons-material';
import ReplyIcon from '@mui/icons-material/Reply';
import AdvisoryCloudLogo from './../../AdvisoryCloudLogo';
import DefaultCompany from './../../../icons/building_round.svg';
import { getRecentlyMessagedBoardMembers } from './../../../services/advisor_services';
import { getAdvisor } from './../../../services/Backend/account';
import { useAccount } from './../../../contexts/Account';
import eventBus, { eventBusValues } from './../../../eventBus';
import CardBoard from './boards';
import emptyState from './emptyState.svg';
import UpgradePlanModal from './../../UpgradePlanModal';
import NewCompanyMemberModal from './../NewContactModal';
import NewCompanyMemberConfirmationModal from './../../AccountSettings/NewMemberConfirmationModal';
import { AccountType } from './../../../services/utils/types';
import InviteContactsModal from './../InviteContactsModal';
import InviteTeamMembersModal from '../InviteTeamMembersModal';
import ACSnackbar from './../../ACSnackbar';
import { RWebShare } from 'react-web-share';

function MemberItem({ advisorId, currentId, companyName, isFreemium }) {
  const [advisorData, setAdvisorData] = useState(false);

  useEffect(() => {
    let isMounted = true;
    function getAdvisorData() {
      getAdvisor(advisorId)
        .then((response) => {
          if (isMounted) {
            setAdvisorData(response.data.getAdvisor);
          }
        })
        .catch(() => {});
    }
    if (advisorId !== currentId) {
      getAdvisorData();
    }
    return () => {
      isMounted = false;
    };
  }, [advisorId]);

  if (
    advisorData &&
    advisorData.accountStatus === 'active' &&
    !advisorData.freeTrialEndsOn &&
    ((advisorData.image && advisorData.image.imageKey) || isFreemium)
  ) {
    return (
      <CardBoard
        onClick={() => visitProfile(advisorData)}
        logo={
          advisorData.image && advisorData.image.imageKey
            ? `${process.env.REACT_APP_IMAGES_URL}${advisorData.image.imageKey}`
            : ''
        }
        memberName={advisorData.displayName}
        companyName={companyName}
        boardMember={advisorData}
        status="member"
        memberType={AccountType.ADVISOR}
        key={`${advisorData.id}-boardCard`}
        className={'boardMemberCard'}
        boardMembersTab
      />
    );
  }
  return null;
}

function visitProfile(advisor) {
  eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
    ...advisor,
    boardRoomView: true,
  });
}

function CompanyLeftMenu() {
  const ref = useRef();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    asLoggedUserDetails,
    accountDetails,
    currentPlan,
    isFreeTrial,
    isFreemium,
    conversations,
    isImpersonated,
    type,
  } = useAccount();
  const { t } = useTranslation();
  const { salesforceValues } = currentPlan;
  const showExFremiumBanner =
    accountDetails.freemiumRegistration &&
    accountDetails.opportunityStage === 'discovery' &&
    !isFreemium;
  const showOnboardingBanner =
    accountDetails.opportunityStage === 'discovery' &&
    accountDetails.onboardingStatus !== 'Not Requisite' &&
    accountDetails.onboardingStatus !== 'Complete' &&
    !accountDetails.freemiumRegistration;
  const showBanner =
    (type == AccountType.ADVISOR &&
      !isFreeTrial &&
      salesforceValues.billingCycle === 'Monthly' &&
      ['Essentials', 'Professional', 'Executive'].includes(
        salesforceValues.tier
      )) ||
    isImpersonated ||
    showExFremiumBanner;
  // showOnboardingBanner;
  const location = useLocation({});
  const history = useHistory();
  const [recentMessagedAdvisors, setRecentMessagedAdvisors] = useState([]);
  const [companyBoardData, setCompanyBoardData] = useState({});
  const [boardAdvisorsData, setBoardAdvisorsData] = useState([]);
  const [companyContacts, setCompanyContacts] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [haveScroll, setHaveScroll] = useState(false);
  const [
    isNewMemberConfirmationModalVisible,
    setIsNewMemberConfirmationModalVisible,
  ] = useState(false);
  const [invitedEmail, setInvitedEmail] = useState('');
  const [errorSnackbarText, setErrorSnackbarText] = useState(
    t('COMPANY-MEMBERS-GENERIC-ERROR')
  );
  const [isErrorSnackbarVisible, setIsErrorSnackbarVisible] = useState(false);
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const paperRef = useRef(null);
  const [displayCount, setDisplayCount] = useState(10);
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);
  const [isNewCompanyMemberModalVisible, setIsNewCompanyMemberModalVisible] =
    useState(false);
  const [inviteSnackbarOpen, setInviteSnackbarOpen] = useState(false);

  const handleShowInviteSnackbar = () => {
    setInviteSnackbarOpen(true);
  };

  const boardOwnerContact = accountDetails.contacts?.find(
    (contact) => contact.isBoardOwner
  );
  const boardOwnerName = boardOwnerContact
    ? `${boardOwnerContact.givenName} ${boardOwnerContact.surName}`
    : 'No board owner found';

  const localAdvisors = useMemo(
    () =>
      accountDetails.boardAdvisors
        .filter((advisor) => advisor.advisorStatus === 'active')
        .sort((a, b) => {
          if (a.joinedOnDate < b.joinedOnDate) return 1;
          if (a.joinedOnDate > b.joinedOnDate) return -1;
          return 0;
        }),
    []
  );

  const loadMoreDisplayedAdvisors = () => {
    setDisplayCount((prevCount) => prevCount + 10);
  };

  const combineRefs = (...refs) => {
    return (current) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(current);
        } else if (ref) {
          ref.current = current;
        }
      });
    };
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(
        `https://app.advisorycloud.com/join/advisors/${accountDetails.slug}/${accountDetails.id}`
      )
      .then(() => {
        setSnackbarOpen(true);
      });
  };

  const handleIcon3Click = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setCompanyBoardData(accountDetails);
  }, []);

  useEffect(() => {
    setHaveScroll(ref.current.scrollHeight > ref.current.clientHeight);
    window.addEventListener('resize', updateDimensions);
  }, [companyContacts, boardAdvisorsData, recentMessagedAdvisors]);

  function updateDimensions() {
    if (ref && ref.current) {
      setHaveScroll(ref.current.scrollHeight > ref.current.clientHeight);
    }
  }

  useEffect(() => {
    if (companyBoardData.contacts && companyBoardData.contacts.length > 0) {
      setCompanyContacts(
        companyBoardData.contacts.filter(
          (contact) =>
            contact.id !== asLoggedUserDetails.userId &&
            (contact.accountStatus === 'active' ||
              contact.accountStatus === 'new')
        )
      );
    }
    if (Object.keys(companyBoardData).length != 0) {
      if (
        companyBoardData.boardAdvisors &&
        companyBoardData.boardAdvisors.length > 0
      ) {
        setBoardAdvisorsData(companyBoardData.boardAdvisors);
      }
    }
  }, [companyBoardData]);

  useEffect(() => {
    (async () => {
      if (conversations.length > 0) {
        const advisors = await getRecentlyMessagedBoardMembers({
          ID: asLoggedUserDetails.userId,
          BOARD_MEMBERS: conversations.map(
            (advisor) => advisor.conversationMemberAccountId
          ),
        });
        setRecentMessagedAdvisors(
          advisors.data.getRecentlyMessagedBoardMembers
        );
      }
    })();
  }, [conversations]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollHeight = paperRef.current.scrollHeight;
      const currentScrollTop = paperRef.current.scrollTop;
      const offsetHeight = paperRef.current.offsetHeight;
      const threshold = 50; // Puedes ajustar este valor según tus necesidades

      if (currentScrollHeight - offsetHeight - threshold <= currentScrollTop) {
        // Verifica si el último fondo medido es diferente del actual
        if (paperRef.current.lastScrollHeight !== currentScrollHeight) {
          loadMoreDisplayedAdvisors();
          // Guarda el último fondo medido
          paperRef.current.lastScrollHeight = currentScrollHeight;
        }
      }
    };

    if (paperRef.current) {
      paperRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (paperRef.current) {
        paperRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  function handleNewCompanyMemberSubmit(data) {
    setIsNewCompanyMemberModalVisible(false);
    setInvitedEmail(data.email);
    setIsNewMemberConfirmationModalVisible(true);
  }

  function handleNewCompanyMemberSubmitError(data) {
    if (data.errors && data.errors.length) {
      if (data.errors[0].message) {
        const error = JSON.parse(data.errors[0].message);
        switch (error.type) {
          case 'Active':
            setErrorSnackbarText(t('NEW-COMPANY-MEMBER-EMAIL-ERROR-ACTIVED'));
            break;
          case 'Invited':
            setErrorSnackbarText(t('NEW-COMPANY-MEMBER-EMAIL-ERROR-INVITED'));
            break;
          case 'Deleted':
            setErrorSnackbarText(t('NEW-COMPANY-MEMBER-EMAIL-ERROR-DELETED'));
            break;
          default:
            setErrorSnackbarText(t('COMPANY-MEMBERS-GENERIC-ERROR'));
            break;
        }
      }
    }
    setIsErrorSnackbarVisible(true);
  }

  function getMainImage() {
    if (
      companyBoardData &&
      companyBoardData.image &&
      companyBoardData.image.id
    ) {
      return `${process.env.REACT_APP_IMAGES_URL}${companyBoardData.image.id}`;
    }
    const boardOwner = accountDetails.contacts.find(
      (contact) => contact.isBoardOwner
    );
    if (boardOwner && boardOwner.image && boardOwner.image.id) {
      return `${process.env.REACT_APP_IMAGES_URL}${boardOwner.image.id}`;
    }
    return DefaultCompany;
  }

  const getLogoId = () => {
    let text = 'premium';
    if (isFreemium) {
      text = 'freemium';
    } else if (isFreeTrial) {
      text = 'freeTrial';
    } else if (currentPlan.isAcPlus) {
      text = 'acPlus';
    }

    if (accountDetails.userSegment) {
      text = `${text}-${accountDetails.userSegment}`;
    }
    return text;
  };

  function getPaperTop() {
    if (isFreeTrial && isImpersonated && !isFreemium) {
      return '100px';
    }
    if (isFreeTrial && !isFreemium) {
      return '50px';
    }
    if (showBanner) {
      return '30px';
    }
    return '0px';
  }

  return (
    <>
      <UpgradePlanModal
        upgradePlanModal={upgradePlanModal}
        setUpgradePlanModal={setUpgradePlanModal}
      />
      <NewCompanyMemberModal
        visible={isNewCompanyMemberModalVisible}
        onDismiss={() => setIsNewCompanyMemberModalVisible(false)}
        onInvitationSend={handleNewCompanyMemberSubmit}
        onInvitationSendError={handleNewCompanyMemberSubmitError}
        companyId={accountDetails.id}
      />
      <NewCompanyMemberConfirmationModal
        email={invitedEmail}
        onDismiss={() => {
          setIsNewMemberConfirmationModalVisible(false);
          setInvitedEmail(null);
        }}
        visible={isNewMemberConfirmationModalVisible}
      />
      <ACSnackbar
        id="company-member-error-snackbar"
        onClose={() => setIsErrorSnackbarVisible(false)}
        open={isErrorSnackbarVisible}
        severity="error"
        text={errorSnackbarText}
      />

      <ACSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={inviteSnackbarOpen}
        autoHideDuration={6000}
        severity="success"
        text="Your contacts have been invited to your AdvisoryCloud"
        onClose={() => setInviteSnackbarOpen(false)}
      />
      <ACSnackbar
        text="URL copied to your clipboard"
        onClose={() => setSnackbarOpen(false)}
        open={snackbarOpen}
        severity="success"
        autoHideDuration={6000}
      />
      <InviteContactsModal onInviteSent={handleShowInviteSnackbar} />
      <InviteTeamMembersModal
        boardOwner={boardOwnerName}
        onInviteSent={handleShowInviteSnackbar}
        accountDetails={accountDetails}
      />
      <div
        style={{
          minWidth: isSM ? '0px' : 280,
          maxWidth: isSM ? '0px' : 350,
          width: isSM ? '0px' : '25%',
          overflow: 'auto',
        }}
      >
        <Paper
          ref={combineRefs(paperRef, ref)}
          sx={{
            minWidth: isSM ? '0px' : 280,
            maxWidth: isSM ? '0px' : 350,
            paddingLeft: isSM ? '0px' : '20px',
            paddingRight: isSM ? '0px' : '28px',
            borderRadius: 0,
            textAlign: 'left',
            display: ({ isSM }) => (isSM ? 'none' : 'flex'),
            width: isSM ? '0px' : '20%',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '30px',
            top: getPaperTop(),
            position: 'fixed',
            // overflowY: 'auto',
            overflowY: 'hidden',
            backgroundColor: '#FBFCFD',
            minHeight: isFreeTrial
              ? 'calc(100vh - 50px)'
              : showBanner
              ? 'calc(100vh - 30px)'
              : '100vh',
            maxHeight: isFreeTrial
              ? 'calc(100vh - 50px)'
              : showBanner
              ? 'calc(100vh - 30px)'
              : '100vh',
            boxShadow: 'none',
            border: 'solid 1px #E6EDFF',
            '&::-webkit-scrollbar': {
              width: '8px',
              backgroundColor: '#F8F8F8',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '5px',
              backgroundColor: '#C7C7C7',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#B7B7B7',
            },
            '&::-webkit-scrollbar-thumb:active': {
              backgroundColor: '#B7B7B7',
              boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            },
            '&:hover': {
              paddingRight: haveScroll ? (isFirefox ? '13px' : '20px') : '28px',
              overflowY: 'auto',
            },
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '-5px',
            }}
            id={getLogoId()}
          >
            <AdvisoryCloudLogo
              removeHyperlink
              onClick={() => {
                eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
                if (location.pathname !== '/dashboard') {
                  history.push('/dashboard');
                }
              }}
              width={153}
            />
          </div>
          <Box
            sx={{
              marginTop: '70px',
              display: ({ isSM }) => (isSM ? 'none' : 'flex'),
              flexDirection: 'column',
              alignItems: 'center',
              background: '#FBFCFD',
              width: '100%',
              borderRadius: '12px',
              padding: '20px 15px 10px 15px',
              cursor: 'pointer',
            }}
            onClick={() => {
              eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
                company: companyBoardData,
              });
            }}
          >
            <div
              role="none"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
              onKeyDown={() => {}}
            >
              <img
                alt=""
                style={{
                  width: 143,
                  height: 143,
                  borderRadius: 146,
                  marginBottom: 10,
                  cursor: 'pointer',
                }}
                className={'profileAvatar'}
                id="b2bCompanyImage"
                src={getMainImage()}
              />
            </div>
            <Typography
              variant="h4"
              id="b2bCompanyDisplayName"
              sx={{
                color: '#232B35',
                textAlign: 'center',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 18,
                lineHeight: '24px',
                letterSpacing: '0.75px',
                cursor: 'pointer',
              }}
            >
              {companyBoardData.displayName}
            </Typography>
          </Box>

          <Box sx={{ marginTop: '15px' }}>
            <EditOutlined
              id="EditProfileIcon"
              onClick={() => {
                eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
                  company: companyBoardData,
                });
              }}
              sx={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                marginRight: '20px',
              }}
            />
            <RWebShare
              data={{
                text: '',
                url: `https://app.advisorycloud.com/join/advisors/${accountDetails.slug}/${accountDetails.id}`,
                title: 'Share your profile',
              }}
            >
              <ReplyIcon
                id="ShareProfileIcon"
                onClick={handleIcon3Click}
                sx={{
                  width: '22px',
                  height: '22px',
                  cursor: 'pointer',
                  transform: 'scaleX(-1)',
                  color: 'white',
                  stroke: 'black',
                  strokeWidth: 1.6,
                }}
              />
            </RWebShare>
          </Box>
          <Box
            id="SharedLinkProfile"
            onClick={handleCopyLink}
            sx={{
              width: '100%',
              height: '35px',
              border: '1px solid #EFF0F6',
              backgroundColor: '#FFFFFF',
              padding: '10px',
              borderRadius: '10px',
              cursor: 'pointer',
              marginTop: '20px',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontFamily: 'Poppins',
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              https://app.advisorycloud.com/join/advisors/
              {accountDetails.slug}/{accountDetails.id}
            </Typography>
          </Box>

          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: 13,
              marginTop: '20px',
              fontWeight: 500,
              letterSpacing: '0.75px',
              //paddingLeft: '21px',
              alignSelf: 'flex-start',
            }}
          >
            Team Members
          </Typography>
          <Box
            id="inviteTeamMembersCta"
            sx={{
              color: '#3171F6',
              display: 'flex',
              alignSelf: 'flex-start',
              paddingLeft: '20px',
              marginBottom: '18px',
              marginTop: '20px',
              cursor: 'pointer',
            }}
            onClick={() =>
              eventBus.dispatch(eventBusValues.triggerInviteTeamModal)
            }
          >
            <AddCircle
              sx={{
                width: '18px',
                height: '18px',
                paddingRight: '4px',
              }}
            />
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '500',
              }}
            >
              Invite Team Members
            </Typography>
          </Box>
          {companyContacts &&
            companyContacts.length > 0 &&
            companyContacts.map((cont) => (
              <div
                style={{
                  width: '100%',
                  paddingBottom: '5px',
                }}
              >
                <CardBoard
                  onClick={() =>
                    eventBus.dispatch(
                      eventBusValues.triggerCompanyDrawerAction,
                      {
                        company: companyBoardData,
                      }
                    )
                  }
                  logo={
                    cont.image && cont.image.id
                      ? `${process.env.REACT_APP_IMAGES_URL}${cont.image.id}`
                      : null
                  }
                  isBoardOwner
                  memberName={cont.displayName}
                  companyName={companyBoardData.displayName}
                  boardMember={cont}
                  memberType={AccountType.COMPANY}
                  status="member"
                  key={`${cont.id}-companyContacts`}
                  className={'companyContacts'}
                  boardOwnerId={
                    cont.isBoardOwner ? 'boardOwnerContactTile' : null
                  }
                />
              </div>
            ))}

          <div
            style={{
              width: '100%',
              paddingTop: '20px',
            }}
            id="boardMemberList"
          >
            {isFreemium ? null : (
              <Box sx={{ marginBottom: '30px' }}>
                {recentMessagedAdvisors &&
                  recentMessagedAdvisors.length > 0 && (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: 13,
                        fontWeight: 500,
                        marginTop: '20px',
                        marginBottom: '10px',
                        letterSpacing: '0.75px',
                        //paddingLeft: '21px',
                        alignSelf: 'flex-start',
                      }}
                      id="b2bConversationList"
                    >
                      Conversations
                    </Typography>
                  )}
                <div
                  style={{
                    width: '100%',
                    backgroundColor: '#FBFCFD',
                  }}
                >
                  {recentMessagedAdvisors &&
                    recentMessagedAdvisors.length > 0 &&
                    recentMessagedAdvisors.map((bm, i) => {
                      return bm != null ? (
                        <CardBoard
                          logo={
                            bm && bm.image && bm.image.imageKey
                              ? `${process.env.REACT_APP_IMAGES_URL}${bm.image.imageKey}`
                              : ''
                          }
                          memberName={bm.displayName}
                          companyName={companyBoardData.displayName}
                          boardMember={bm}
                          memberType={AccountType.ADVISOR}
                          status="member"
                          isRecentlyMessaged
                          key={`${bm.id}-recentMessagedAdvisorsMenu-${i}`}
                          className={'conversationTile'}
                          showLastMessage
                        />
                      ) : null;
                    })}
                </div>
              </Box>
            )}
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: 13,
                fontWeight: 500,
                letterSpacing: '0.75px',
                //paddingLeft: '21px',
                alignSelf: 'flex-start',
              }}
              id="b2bAdvisoryBoardMembers"
            >
              Your
              {accountDetails.userSegment === 'managed_advisory_board'
                ? ' Advisory Board'
                : ' AdvisoryCloud'}
            </Typography>

            {(isFreemium || (!isFreeTrial && !isFreemium)) && (
              <Box
                id="inviteYourContactsCta"
                sx={{
                  color: '#3171F6',
                  display: 'flex',
                  alignSelf: 'flex-start',
                  paddingLeft: '20px',
                  marginTop: '20px',
                  marginBottom: '12px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  eventBus.dispatch(eventBusValues.triggerInviteContactsModal)
                }
              >
                <AddCircle
                  sx={{
                    width: '18px',
                    height: '18px',
                    paddingRight: '4px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  Invite Your Contacts
                </Typography>
              </Box>
            )}

            <div
              style={{
                width: '100%',
                paddingBottom: '10px',
                backgroundColor: '#FBFCFD',
              }}
            >
              {localAdvisors &&
                localAdvisors
                  .slice(0, displayCount)
                  .map((bm) => (
                    <MemberItem
                      key={`${bm.id}-localAdvisors`}
                      advisorId={bm.id}
                      currentId={accountDetails.id}
                      companyName={companyBoardData.displayName}
                      isFreemium={isFreemium}
                    />
                  ))}

              {boardAdvisorsData.length === 0 &&
                (isFreemium ? null : (
                  <Box
                    id="sidebarSearchAdvisorsTile"
                    onClick={() => history.push('/advisors/search')}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '76px',
                      background: '#F5F7FA',
                      borderRadius: '12px',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      color: '#97A3B7',
                      paddingRight: '18px',
                      paddingLeft: '24px',
                      marginTop: '16px',
                    }}
                  >
                    <img
                      src={emptyState}
                      style={{
                        color: '#C7D0DE',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        alignItems: 'start',
                        height: '42px',
                        width: '42px',
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: 12,
                        fontWeight: 400,
                        color: '#97A3B7',
                        marginLeft: '11px',
                      }}
                    >
                      Search advisors to add to your
                      {accountDetails.userSegment === 'managed_advisory_board'
                        ? ' advisory board'
                        : ' AdvisoryCloud'}
                    </Typography>
                  </Box>
                ))}
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
}

export default CompanyLeftMenu;
