import React from 'react';
import { useAccount } from '../contexts/Account';
import Dashboard from '../scenes/AdvisorDashboardV2';
import IndividualPost from '../scenes/IndividualPost';
import SearchCompanies from '../scenes/SearchCompanies';
import AdvisorAccount from '../scenes/AdvisorAccount';
import TopBar from './Topbar';
import { Switch, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import Treatment from './../components/Treatment';
import { featureFlags } from './../splitSetup';
import BoardroomAdvisorView from './Advisor/Boardroom';
import AdvisoryBoards from '../scenes/v2/AdvisoryBoards';
import Boardroom from '../scenes/v2/Boardroom';
import PersonalAdvisoryBoards from '../scenes/v2/PersonalAdvisoryBoards';

const useStyles = ({ isFreemium }) => ({
  baseContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: isFreemium ? '50px' : '0px',
    backgroundColor: '#f4f8ff',
  },
  impersonationBanner: {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  },
  contentContainer: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 'calc(100vh - 50px)',
  },
  mainViewContainer: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  viewContainer: {
    display: 'flex',
    flex: 'auto',
    overflowX: 'hidden',
  },
});

function Layout() {
  const { isImpersonated, isFreemium, currentUserInfo } = useAccount();
  const classes = useStyles({ isFreemium });

  return (
    <Treatment
      userId={currentUserInfo.username}
      splitNames={featureFlags.master_redesign}
      updateOnSdkTimedout
      evaluatedComponent={
        <div style={classes.baseContainer}>
          <Treatment
            userId={currentUserInfo.username}
            splitNames={featureFlags.board_directory_redesign}
            updateOnSdkTimedout
            evaluatedComponent={<TopBar />}
            conditionFlag
            conditionFailedComponent={<TopBar legacy />}
          />
          {isImpersonated && (
            <div style={classes.impersonationBanner}>
              {/* Aquí podrías poner el componente de la banner de impersonation si existe */}
            </div>
          )}
          <div style={classes.contentContainer}>
            <div style={classes.mainViewContainer}>
              <Box sx={classes.viewContainer}>
                <Switch>
                  <Route path="/v2/dashboard" component={Dashboard} />
                  <Route path="/v2/post/:postId?" component={IndividualPost} />
                  <Route path="/v2/account" component={AdvisorAccount} />
                  <Route
                    path="/v2/:companySlug/boardroom"
                    render={() => (
                      <Treatment
                        userId={currentUserInfo.username}
                        splitNames={featureFlags.boardroom_redesign}
                        updateOnSdkTimedout
                        evaluatedComponent={<Boardroom />}
                        conditionFlag
                        conditionFailedComponent={<BoardroomAdvisorView />}
                      />
                    )}
                  />
                  <Route path="/v2/account" component={AdvisorAccount} />
                  <Route path="/v2/personal-advisory-boards" component={PersonalAdvisoryBoards} />

                  <Treatment
                    userId={currentUserInfo.username}
                    splitNames={featureFlags.board_directory_redesign}
                    updateOnSdkTimedout
                    evaluatedComponent={
                      <>
                        <Route
                          path="/v2/board-directory"
                          component={AdvisoryBoards}
                        />
                        <Route
                          path="/peer-directory"
                          component={AdvisoryBoards}
                        />
                      </>
                    }
                    conditionFlag
                    conditionFailedComponent={
                      <Route
                        path="/v2/board-directory"
                        component={SearchCompanies}
                      />
                    }
                  />
                </Switch>
              </Box>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default Layout;
