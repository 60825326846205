import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import {
  ChevronLeft,
  Launch,
  FavoriteBorder,
  Close,
  UpgradeOutlined,
} from '@mui/icons-material';
import { useAccount } from './../../../contexts/Account';
import eventBus, { eventBusValues } from './../../../eventBus';
import UpgradePlanModal from './../../UpgradePlanModal';
import NewCompanyMemberModal from './../NewContactModal';

const useStyles = () => ({
  container: {
    marginTop: '24px',
    paddingLeft: '20px',
  },
  backButton: {
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '160px',
    padding: '0px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    position: 'absolute',
    color: '#232B35',
  },
  menuItemsContainer: {
    marginTop: '40px',
  },
  iconStyle: {
    marginRight: '17.5px',
    marginTop: '6px',
    fontSize: '20px',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    paddingRight: '20px',
    paddingLeft: '20px',
    color: '#646D7A',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingBottom: '4px',
    paddingTop: '6px',
    marginRight: '-20px',
    marginLeft: '-20px',
    width: '260px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    '&:hover': {
      color: '#232B35',
    },
  },
  menuItemLogout: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    paddingRight: '20px',
    paddingLeft: '20px',
    color: '#646D7A',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingBottom: '4px',
    paddingTop: '6px',
    marginRight: '-20px',
    marginLeft: '-20px',
    width: '260px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    '&:hover': {
      color: '#232B35',
    },
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatarDetailsContainer: {
    marginLeft: '10px',
  },
  closeButtonContainer: {
    position: 'absolute',
    fontSize: '30px',
    left: '85%',
    top: '22px',
    zIndex: 2,
    color: '#97A3B7',
  },
});

function ProfileList({ setIsOpen, onBackClick }) {
  const classes = useStyles();
  const { accountDetails, signOut, currentPlan, isFreemium } = useAccount();
  const history = useHistory();
  const { t } = useTranslation();
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);
  const [isNewCompanyMemberModalVisible, setIsNewCompanyMemberModalVisible] =
    useState(false);

  async function onLogoutClick() {
    await signOut();
  }

  function openProfile() {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      hidePostSection: false,
      company: accountDetails,
    });
    setIsOpen(false);
    onBackClick(true);
  }

  function navigateInApp(path) {
    history.push(path);
  }

  function openUpgradeModal() {
    eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {
      action: () => {},
    });
  }

  function getOptions() {
    return (
      <div style={classes.menuItemsContainer}>
        <button
          id="companyViewProfileMobileSubMenuOption"
          style={classes.menuItem}
          onClick={openProfile}
          type="button"
        >
          <Launch sx={classes.iconStyle} />

          {t('VIEW-PROFILE-MENU-ITEM-TEXT')}
        </button>
        {isFreemium && (
          <button
            id="companyViewProfileMobileSubMenuOption"
            style={classes.menuItem}
            onClick={openUpgradeModal}
            type="button"
          >
            <UpgradeOutlined sx={classes.iconStyle} />
            View Upgrade Options
          </button>
        )}
        <button
          id="companyAccountSettingsMobileSubMenuOption"
          style={classes.menuItem}
          onClick={() => navigateInApp('/account')}
          type="submit"
        >
          <FavoriteBorder sx={classes.iconStyle} />
          Account Settings
        </button>
        <button
          id="companyLogOutMobileSubMenuOption"
          style={classes.menuItemLogout}
          type="submit"
          onClick={onLogoutClick}
        >
          {t('LOGOUT-MENU-ITEM-TEXT')}
        </button>
      </div>
    );
  }

  return (
    <div style={classes.container}>
      <button
        id="companyMobileReturnOption"
        type="submit"
        style={classes.backButton}
        onClick={onBackClick}
      >
        <ChevronLeft sx={{ fontSize: '30px' }} />
        Main Menu
      </button>
      <div style={classes.closeButtonContainer}>
        <Close
          onClick={() => {
            setIsOpen(false);
            onBackClick(true);
          }}
        />
      </div>
      {getOptions()}
      <UpgradePlanModal
        upgradePlanModal={upgradePlanModal}
        setUpgradePlanModal={setUpgradePlanModal}
      />
      <NewCompanyMemberModal
        visible={isNewCompanyMemberModalVisible}
        onDismiss={() => setIsNewCompanyMemberModalVisible(false)}
        onInvitationSend={console.log}
        onInvitationSendError={console.log}
        companyId={accountDetails.id}
      />
    </div>
  );
}

export default ProfileList;
