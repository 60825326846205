import React, { useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import CustomIcon from './../../../../icons/customIcon';
import TimeElapsed from './../../../../utils/TimeElapsed';
import VerticalElipsiesMenu from './RightHandVerticalElipsies';
import PostTypeIcon from './PostTypeDecorator';
import BoardMeetingIconBadge from './BoardMeetingIconBadge';
import PostHeaderAvatar from './PostHeaderAvatar';
import eventBus, { eventBusValues } from './../../../../eventBus';
import { useAccount } from './../../../../contexts/Account';
import BoardMeetingPopUp from './../../BoardMeetingPopUp';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { EditOutlined } from '@mui/icons-material';
import Treatment from '../../../Treatment';
import { featureFlags } from '../../../../splitSetup';

const useStyles = (isSM, type) => ({
  postCardHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: isSM && type === 'question' ? 'column-reverse' : '',
    width: '100%',
  },
  rightGroup: {
    display: 'flex',
    width: 'fit-content',
    height: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightText: {
    display: 'flex',
    textAlign: 'end',
    justifyContent: 'end',
    alignItems: 'end',
    flexDirection: 'column',
  },
  iconContainer: {
    marginRight: '5px',
    display: 'flex',
    flexDirection: isSM ? 'column-reverse' : 'row',
    justifyContent: isSM ? 'end' : 'center',
    alignItems: isSM ? 'end' : 'center',
  },
  pinnedFont: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '32px',
    whiteSpace: 'nowrap',
    color: '#646D7A',
  },
  pinnedIcon: {
    color: '#FFC068',
    fontSize: '17px',
    display: 'flex',
    alignSelf: 'center',
    marginRight: '5px',
  },
  pillContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: isSM ? '10px' : '20px',
  },
});

function PostCardHeader(props) {
  const {
    postData,
    noEllipsis,
    type,
    initialTime,
    companyName,
    dashboardFormat,
    editClick,
    deleteClick,
    isOpportunityView,
    companyAvatar,
    pinClick,
    isPinned,
    setTimeLive,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [openBoardMeetingPost, setOpenBoardMeetingPost] = useState(false);
  const { currentUserInfo } = useAccount();
  const { asLoggedUserDetails, isImpersonated } = useAccount();
  const classes = useStyles(isSM, type);
  const isBoardMeetingPost = postData.type === 'boardMeeting';

  function typeGroupSelector() {
    if (isBoardMeetingPost) {
      if (
        asLoggedUserDetails.userId === postData.companyContactId &&
        isImpersonated
      ) {
        return (
          <VerticalElipsiesMenu
            editClick={() => setOpenBoardMeetingPost(true)}
            pinClick={pinClick}
            isPinned={isPinned}
            type={type}
          />
        );
      }
    } else if (!noEllipsis) {
      return (
        <VerticalElipsiesMenu
          editClick={editClick}
          pinClick={pinClick}
          isPinned={isPinned}
          deleteClick={deleteClick}
          type={type}
        />
      );
    }
    return null;
  }

  function timeElapsedResults() {
    return isOpportunityView || isBoardMeetingPost ? (
      ''
    ) : (
      <TimeElapsed
        initialTime={initialTime}
        dashboardFormat={dashboardFormat ? 1 : 0}
        size={isSM ? 'sm' : 'lg'}
      />
    );
  }

  function pinnedText() {
    if (isPinned) {
      return (
        <div style={classes.pillContainer}>
          {isSM ? null : (
            <CustomIcon style={classes.pinnedIcon} iconname="pin" />
          )}
          <Typography sx={classes.pinnedFont}>Pinned post</Typography>
        </div>
      );
    }
    return null;
  }

  return (
    <>
      <div style={classes.postCardHeaderContainer}>
        <PostHeaderAvatar
          titleName={companyName}
          subtitle={timeElapsedResults()}
          isBoardMeeting
          fontSize={isSM ? 'sm' : 'lg'}
          image={companyAvatar}
          avatarClick={() =>
            eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
              companyId: postData.companyId,
              hidePostSection: true,
            })
          }
        />
        <div style={classes.rightGroup}>
          <Treatment
            userId={
              currentUserInfo && currentUserInfo.username
                ? currentUserInfo.username
                : 'not_id'
            }
            splitNames={featureFlags.stop_gap_b2b_redesign}
            updateOnSdkTimedout
            evaluatedComponent={
              <div style={classes.rightText}>
                <div style={classes.iconContainer}>
                  {pinnedText()}
                  {isBoardMeetingPost ? (
                    <BoardMeetingIconBadge
                      postData={postData}
                      setTimeLive={setTimeLive}
                      dashboardFormat={dashboardFormat}
                      isPinned={isPinned}
                    />
                  ) : null}
                  {type === 'question' ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          display:
                            currentUserInfo.accountType === 'advisor'
                              ? 'none'
                              : 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '249px',
                          height: '48px',
                          borderRadius: '10px',
                          backgroundColor: '#2B71F6',
                          marginBottom: { xxs: '10px', sm: '0px' },
                        }}
                      >
                        <LightbulbIcon
                          style={{ color: 'white', height: '18px' }}
                        />
                        <Typography
                          sx={{
                            color: '#FFFFFF',
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '32px',
                            letterSpacing: '0.75px',
                            marginLeft: '5px',
                          }}
                        >
                          Current Challenge
                        </Typography>
                      </Box>
                      <Box
                        onClick={editClick}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: { xxs: '70px', sm: '121px' },
                          height: '48px',
                          borderRadius: '10px',
                          backgroundColor: '#DBF7EB',
                          marginLeft: '5px',

                          cursor: 'pointer',
                        }}
                      >
                        <EditOutlined
                          style={{ color: '#232B35', height: '18px' }}
                        />
                        {!isSM && (
                          <Typography
                            sx={{
                              color: '#232B35',
                              fontSize: '16px',
                              fontWeight: 500,
                              lineHeight: '32px',
                              letterSpacing: '0.75px',
                              marginLeft: '0px',
                            }}
                          >
                            Change
                          </Typography>
                        )}
                      </Box>
                      {asLoggedUserDetails.userId ===
                        postData.companyContactId &&
                        isImpersonated && <>{typeGroupSelector()}</>}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <PostTypeIcon type={type} />
                      {typeGroupSelector()}
                    </Box>
                  )}
                </div>
              </div>
            }
            conditionFlag
            conditionFailedComponent={
              <>
                <PostTypeIcon type={type} />
                {typeGroupSelector()}
              </>
            }
          />
        </div>
      </div>
      <BoardMeetingPopUp
        open={openBoardMeetingPost}
        postData={postData}
        closeAction={() => setOpenBoardMeetingPost(false)}
      />
    </>
  );
}

export default PostCardHeader;
