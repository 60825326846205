import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Icono de tachita
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import dateFormat from './../../../utils/dateFormat';
import ACSnackbar from './../../../components/ACSnackbar';

const CompanyDialog = ({ open, onClose, company, memberBoard }) => {
  const [snackMessage, setSnackMessage] = useState('');
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);

  const handleCopy = () => {
    const dateObj = memberBoard.createdAt
      ? new Date(memberBoard.createdAt)
      : new Date();
    const year = dateFormat(dateObj, 'yy');
    const month = dateFormat(dateObj, 'mm');
    const htmlToCopy = `
      <strong>${company.companyName} Advisory Board</strong>
      <br /><br />
      ${company.headline} 
      <br /><br />
      Advisory board member since ${year}/${month}.
    `.trim();

    const blob = new Blob([htmlToCopy], { type: 'text/html' });
    const data = [new ClipboardItem({ 'text/html': blob })];

    navigator.clipboard
      .write(data)
      .then(() => {
        setSnackMessage(
          'Your board membership has been copied to your clipboard'
        );
        setSuccessSnackbar(true);
      })
      .catch((err) => {
        setSnackMessage('Error copying to clipboard');
        setErrorSnackbar(true);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: 544,
          maxWidth: '100%',
          maxHeight: '100%',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            color: '#000',
            position: 'absolute',
            top: 2,
            right: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: { xxs: '8px 15px', xs: '16px 30px' } }}>
        <Typography
          sx={{
            fontSize: { xxs: '24px', xs: '32px' },
            fontWeight: 600,
            color: '#001030',
            lineHeight: { xxs: '32px', xs: '40px' },
            marginBottom: { xxs: '8px', xs: '16px' },
          }}
        >
          Add to resume:
        </Typography>
        <Box
          sx={{
            border: '1px solid #C9CDD3',
            borderRadius: '8px',
            padding: '16px',
            marginBottom: '16px',
          }}
        >
          <Typography
            sx={{
              fontSize: { xxs: '16px', xs: '20px' },
              fontWeight: 600,
              color: '#001030',
              mb: '8px',
            }}
          >
            {company.companyName} Advisory Board
          </Typography>
          <Grid container spacing={1}>
            <Grid item xxs={9} xs={10}>
              <Typography
                sx={{
                  fontSize: { xxs: '12px', xs: '14px' },
                  fontWeight: 500,
                  color: '#001030',
                  pr: { xxs: '0px', xs: '10px' },
                }}
              >
                {company.headline}
              </Typography>
            </Grid>
            <Grid
              item
              xxs={3}
              xs={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                onClick={handleCopy}
                startIcon={<ContentCopyIcon />}
                sx={{
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: 500,
                  padding: '6px 16px',
                  color: '#2B71F6',
                }}
              >
                Copy
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Typography
          sx={{
            fontSize: { xxs: '12px', xs: '13px' },
            color: '#666',
            marginBottom: '16px',
          }}
        >
          You can add your board membership to your resume, LinkedIn and other
          professional materials.
        </Typography>
      </DialogContent>
      <ACSnackbar
        open={successSnackbar}
        text={snackMessage || ''}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
      />
      <ACSnackbar
        open={errorSnackbar}
        text={snackMessage || ''}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
      />
    </Dialog>
  );
};

export default CompanyDialog;
