import React, { useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import FeaturedBoardTile from './FeaturedBoardTile';
import HelperTile from './HelperTile';
import Treatment from '../Treatment';
import { featureFlags } from '../../splitSetup';
import { ReactComponent as Building1 } from '../../icons/building_1.svg';
import { ReactComponent as CheckIcon } from './icons/check.svg';
import { ReactComponent as ACLogo } from './icons/ac_icon.svg';
import { useAccount } from '../../contexts/Account';
import CalendarProviderButton from './CalendarProviderButton';
import { SnackbarType } from '../../services/utils/types';
import ACSnackbar from '../ACSnackbar';

const useStyles = (isSM) => ({
  tilesContainer: {
    display: 'flex',
    displayDirection: 'row',
    flexDirection: isSM ? 'column' : '',
    width: '100%',
    marginBottom: '20px',
    justifyContent: 'space-between',
  },
});

const TYPES = {
  dashboard: 'DASHBOARD',
  meetings: 'MEETINGS',
  search: 'SEARCH',
  boardroom: 'BOARDROOM',
  networking: 'NETWORKING',
};

function AdvisorTopTiles(props) {
  const { currentUserInfo, accountDetails, isFreemium } = useAccount();
  const { type, cardId, tileTitle, tileText } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLessThan1040px = useMediaQuery((theme) =>
    theme.breakpoints.down([1040])
  );
  const classes = useStyles(isSM);

  const [googleSync, setGoogleSync] = useState(
    accountDetails?.calendarTokens?.google || false
  );
  const [appleSync, setAppleSync] = useState(
    accountDetails?.calendarTokens?.apple || false
  );

  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(SnackbarType.SUCCESS);

  const advisorId = accountDetails?.id;
  const companyIds = accountDetails?.boards?.memberBoards?.map(
    (board) => board.id
  );

  const showSnackbar = (message, severity = SnackbarType.SUCCESS) => {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setSnackbarOpen(true);
  };

  return (
    <div style={classes.tilesContainer}>
      <FeaturedBoardTile shrinked={type == TYPES.search} />
      <Treatment
        userId={currentUserInfo.username}
        splitNames={featureFlags.calendar_subscription}
        updateOnSdkTimedout
        conditionFlag
        evaluatedComponent={
          <Box
            id="Board Meeting Integration"
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: { xxs: '100%', sm: '450px', md: '550px' },
              height: { sm: '238px' },
              backgroundColor: '#F5F7FA',
              borderRadius: '16px',
              padding: '20px 25px',
              marginLeft: { xxs: '0px', sm: '10px', md: '5px' },
              overflow: 'hidden',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 600,
                  lineHeight: '32px',
                  letterSpacing: '.05px',
                  marginRight: '5px',
                }}
              >
                Sync Board Meetings to Your Calendar
              </Typography>
              {googleSync || appleSync ? (
                <CheckIcon style={{ width: '30px', height: '30px' }} />
              ) : (
                <ACLogo style={{ width: '40px', height: '40px' }} />
              )}
            </Box>
            <Typography
              sx={{
                maxWidth: '440px',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '27px',
                letterSpacing: '.05px',
                marginTop: '10px',
              }}
            >
              {googleSync || appleSync
                ? 'Calendar Sync is active, all new board meetings will automatically be added to your calendar.'
                : 'Automatically have current and future board meetings added to your Apple or Google calendar for the boards you join.'}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 'auto',
                zIndex: 1,
              }}
            >
              <CalendarProviderButton
                syncStates={{
                  googleSync,
                  appleSync,
                }}
                setSyncStates={{
                  setGoogleSync,
                  setAppleSync,
                }}
                advisorId={advisorId}
                companyIds={companyIds}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                showSnackbar={showSnackbar}
              />
            </Box>
            <Building1
              style={{
                position: 'absolute',
                bottom: '-10px',
                right: '0px',
                width: '200px',
                height: '200px',
              }}
            />
          </Box>
        }
        conditionFailedComponent={
          <HelperTile
            title={tileTitle}
            text={tileText}
            cardId={cardId}
            isLessThan1040px={isLessThan1040px}
            shrinked={type == TYPES.search}
            cursor={type == TYPES.search ? undefined : 'default'}
          />
        }
      />
      <ACSnackbar
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
        open={snackbarOpen}
        text={snackMessage}
        severity={snackSeverity}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        autoHideDuration={5000}
      />
    </div>
  );
}

export default AdvisorTopTiles;
