import React, { useEffect } from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import BoardCard from '../../advisorComponents/boardCard';
import { useAccount } from '../../contexts/Account';
import eventBus, { eventBusValues } from './../../eventBus';

const MyBoards = () => {
  const { accountDetails } = useAccount();
  const [boardsData, setBoardsData] = React.useState(
    accountDetails.boards.memberBoards
  );

  useEffect(() => {
    eventBus.on(eventBusValues.advisorAnsweredInvitationV2, (board) => {
      setBoardsData([...boardsData, board]);
    });
    return () => {
      eventBus.remove(eventBusValues.advisorAnsweredInvitationV2);
    };
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        padding: '20px',
        borderRadius: '16px',
        backgroundColor: '#fff',
        marginTop: '20px',
        boxShadow: 'none',
      }}
      id="my-boards-tile"
    >
      <Typography
        sx={{
          fontSize: '20px',
          font: 'Poppins',
          fontWeight: 600,
          lineHeight: '26px',
          marginBottom: '0px',
          color: '#001030',
        }}
      >
        My Boards
      </Typography>
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: 14,
          color: '#4D586E',
          fontStyle: 'italic',
          marginTop: '5px',
        }}
      >
        Join as many boards as you like. You can join or leave boards at any
        time. New boards are added daily. You can also set up your own board.
      </Typography>

      <Grid container spacing={3}>
        {accountDetails.boards.memberBoards.length > 0 ? (
          accountDetails.boards.memberBoards.map((board, index) => (
            <Grid item xs={12} sm={6} md={4} sx={{ width: '100%' }} key={index}>
              <BoardCard board={board} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={6} md={4} sx={{ width: '100%' }} key="join">
            <BoardCard board={{ companyName: 'Join Advisory Boards!' }} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default MyBoards;
