import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';
import CalendarSyncMenu from '../../../advisorComponents/CalendarSyncMenu';
import { useAccount } from '../../../contexts/Account';
import ACSnackbar from '../../../components/ACSnackbar';

const ConfirmRequestToSpeakDialog = ({ open, onClose }) => {
  const { accountDetails } = useAccount();
  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  const [googleSync, setGoogleSync] = useState(
    accountDetails?.calendarTokens?.google || false
  );
  const [appleSync, setAppleSync] = useState(
    accountDetails?.calendarTokens?.apple || false
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [isLoading, setIsLoading] = useState(false);

  const onSyncCalendar = (event) => {
    setCalendarAnchorEl(event.currentTarget);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          width: 544,
          maxWidth: '100%',
          maxHeight: '100%',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            color: '#000',
            position: 'absolute',
            top: 2,
            right: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '16px 30px' }}>
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 600,
            color: '#001030',
            lineHeight: '42px',
            marginBottom: '16px',
          }}
        >
          Success!
        </Typography>
        <Typography
          sx={{
            fontSize: '13px',
            color: '#666',
            marginBottom: '16px',
          }}
        >
          The advisory board coordinator will reach you to and confirm if there
          is still space. Please make sure you have synced your calendar so that
          you can attend this board meeting.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: 2,
            py: '15px',
            flexDirection: { xxs: 'column', sm: 'row' },
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            fullWidth
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              padding: '6px 12px',
              borderRadius: 2,
              borderColor: '#2B71F6',
              color: '#2B71F6',
              borderWidth: '1px',
              backgroundColor: 'white',
            }}
          >
            Close
          </Button>

          <Button
            onClick={onSyncCalendar}
            startIcon={<SyncIcon />}
            fullWidth
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              padding: '6px 12px',
              borderRadius: 2,
              borderColor: '#2B71F6',
              color: 'white',
              borderWidth: '1px',
              backgroundColor: '#2B71F6',
              '&:hover': {
                backgroundColor: '#2B71F6',
              },
            }}
          >
            Sync Calendar
          </Button>
        </Box>
      </DialogContent>

      <CalendarSyncMenu
        anchorEl={calendarAnchorEl}
        setAnchorEl={setCalendarAnchorEl}
        syncStates={{
          googleSync,
          appleSync,
        }}
        setSyncStates={{
          setGoogleSync,
          setAppleSync,
        }}
        advisorId={accountDetails?.id}
        companyIds={accountDetails?.boards?.memberBoards?.map(
          (board) => board.id
        )}
        setIsLoading={setIsLoading}
        showSnackbar={(message, severity) => {
          setSnackMessage(message);
          setSnackSeverity(severity);
          setSnackbarOpen(true);
        }}
      />
      <ACSnackbar
        open={snackbarOpen}
        text={snackMessage}
        severity={snackSeverity}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        autoHideDuration={5000}
      />
    </Dialog>
  );
};

export default ConfirmRequestToSpeakDialog;
