import React, { useEffect, useState } from 'react';
import { Typography, Button, useMediaQuery, Box, Avatar } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import { useAccount } from '../../../contexts/Account';
import DefaultCompany from '../../../icons/building_round.svg';
import eventBus, { eventBusValues } from '../../../eventBus';

const useStyles = ({ isSM }) => ({
  title: {
    fontFamily: 'Poppins',
    fontSize: '38px',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.43px',
    textAlign: 'center',
    maxWidth: '90%',
  },
  subtitle: {
    marginTop: '10px',
    maxWidth: isSM ? '90%' : 460,
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.3px',
    textAlign: 'center',
  },
  watchVideoText: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.75px',
    textAlign: 'left',
  },
  mainButton: {
    width: '205px',
    height: '48px',
    borderRadius: '25px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textAlign: 'left',
    textTransform: 'none',
    boxShadow: 'none',
    marginTop: '10px',
    marginBottom: isSM ? '5px' : '10px',
    background: '#4d6fe6',
    px: '0px',
    '&:hover': {
      backgroundColor: '#4d6fe6',
    },
  },
  secondaryButton: {
    width: '205px',
    height: '48px',
    borderRadius: '25px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textAlign: 'left',
    textTransform: 'none',
    boxShadow: 'none',
    marginTop: isSM ? '0px' : '10px',
    marginBottom: '10px',
    color: '#4d6fe6',
    border: '2px solid #4d6fe6',
    backgroundColor: 'white',
    px: '0px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
});

const CompanyTopTiles = ({ setFilterBy }) => {
  const { accountDetails } = useAccount();
  const history = useHistory();
  const location = useLocation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const classes = useStyles({ isSM });

  useEffect(() => {
    setFilterBy(['question', 'boardMeetings']);
  }, []);

  return (
    <div
      style={{
        width: '100%',
        minHeight: isSM ? '362px' : '326px',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '16px',
        marginBottom: '15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '286px',
          height: '286px',
          borderRadius: '50%',
          background: 'rgba(215, 241, 253, 0.5)',
          position: 'absolute',
          bottom: '-65px',
          left: '-53px',
          zIndex: '-1',
        }}
      ></div>
      <div
        style={{
          width: '300px',
          height: '300px',
          borderRadius: '50%',
          background: 'rgba(219, 247, 235, 0.5)',
          position: 'absolute',
          top: '-150px',
          right: '-150px',
          zIndex: '-1',
        }}
      ></div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={
            accountDetails &&
            accountDetails.image &&
            accountDetails.image.id &&
            !accountDetails.image.id.includes('undefined')
              ? `${process.env.REACT_APP_IMAGES_URL}${accountDetails.image.id}`
              : DefaultCompany
          }
          alt="Company Logo"
          sx={{
            width: '110px',
            height: '110px',
            marginBottom: '15px',
          }}
        />
        <Typography
          sx={classes.title}
        >{`${accountDetails.companyName}`}</Typography>
        <Typography sx={classes.subtitle}>
          Invite your team, your contacts, and advisors from our network to join
          your advisory board
        </Typography>

        <Box
          sx={{
            display: { xxs: 'grid', sm: 'flex' },
            justifyContent: 'center',
            gap: '7px',
          }}
        >
          <Button
            variant="contained"
            sx={classes.mainButton}
            id="TopBanner-SearchNetwork"
            onClick={() => history.push('/advisors/search')}
          >
            Search our Network
          </Button>
          <Button
            variant="variant"
            sx={classes.secondaryButton}
            id="TopBanner-InviteContacts"
            onClick={() => {
              eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
                company: accountDetails,
              });
            }}
          >
            Invite your contacts
          </Button>
        </Box>
        <div
          id="TopBanner-WatchVideo"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <PlayCircleOutlineOutlinedIcon
            style={{
              fontSize: '15px',
              marginRight: '3px',
            }}
          />
          <Typography sx={classes.watchVideoText}>Take a Tour</Typography>
        </div>
      </div>
    </div>
  );
};

export default CompanyTopTiles;
