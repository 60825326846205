export default {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_KEY,
    key: 'web-users',
  },
};

export const featureFlags = {
  publicProfilesCustomMetadata: 'public_profiles_custom_metadata',
  B2B_freemium_AOBF: 'B2B_freemium_AOBF',
  b2b_profile_updates: 'b2b_profile_updates',
  itj_notes: 'itj_notes',
  itj_advisor: 'itj_advisor',
  opensearch_migration: 'opensearch_migration',
  calendar_subscription: 'calendar_subscription',
  master_redesign: 'master_redesign',
  boardroom_redesign: 'boardroom_redesign',
  board_directory_redesign: 'board_directory_redesign',
  stop_gap_b2b_redesign: 'stop_gap_b2b_redesign',
  company_navbar: "company_navbar",
};
