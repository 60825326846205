import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Menu } from '@mui/icons-material';
import AdvisoryCloudLogo from './../../AdvisoryCloudLogo';
import eventBus, { eventBusValues } from './../../../eventBus';
import { useAccount } from './../../../contexts/Account';

const useStyles = (isSM) => ({
  container: {
    display: isSM ? 'flex' : 'none',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: '#fff',
    borderBottom: '1px solid #E6EDFF',
    width: '100%',
    height: '50px',
    position: 'fixed',
    zIndex: '99',
  },
  menuButton: {
    padding: '0px',
    border: 'none',
    backgroundColor: 'transparent',
    color: '#000',
    fontSize: '30px',
    marginTop: '5px',
  },
});

function CompanyTopMenu() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { isFreeTrial, isFreemium, currentPlan, accountDetails } = useAccount();

  function getTopValue() {
    // if (isFreemium) {
    //   return '64px';
    // }
    if (isFreeTrial && !isFreemium) {
      return '50px';
    }
    return '0px';
  }

  const getLogoId = () => {
    let text = 'premium';
    if (isFreemium) {
      text = 'freemium';
    } else if (isFreeTrial) {
      text = 'freeTrial';
    } else if (currentPlan.isAcPlus) {
      text = 'acPlus';
    }

    if (accountDetails.userSegment) {
      text = `${text}-${accountDetails.userSegment}`;
    }
    return text;
  };

  return (
    <div style={{ ...classes.container, top: getTopValue() }}>
      <Box id={getLogoId()}>
        <AdvisoryCloudLogo
          id="AdvisoryCloudLogo"
          removeHyperlink
          onClick={() => {}}
          width={150}
          height={55}
        />
      </Box>

      <button
        onClick={() =>
          eventBus.dispatch(eventBusValues.mobileCompanyMenuTrigger)
        }
        style={classes.menuButton}
        type="submit"
      >
        <Menu id="companyHamburgerMobileMenu" style={{ color: '#232B3' }} />
      </button>
    </div>
  );
}

export default CompanyTopMenu;
