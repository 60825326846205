import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { searchCompanies } from '../../services/advisor_services';
import { useAccount } from '../../contexts/Account';
import AdvisorSearchCompanies from '../../components/Advisor/SearchCompanies';
import SceneContentStateSelector from '../../components/SceneContentStateSelector';
import {
  EndpointResponseObjectName,
  EndpointParameterFilters,
} from '../../services/utils/types';
import AdvisoryBoardsHeader from '../../components/v2/AdvisoryBoardsHeader';
import { isNil } from '../../services/utils';
import CompaniesFilter from '../../components/v2/CompaniesFilter';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    // maxWidth: '1200px',
    padding: `20px ${isSM ? '10px' : '30px'}`,
    marginTop: '24px',
    width: '100%',
    backgroundColor: 'white',
  },
});

function isPeerPage(location) {
  if (location.pathname.includes('/board-directory')) {
    return false;
  }
  return true;
}

function AdvisoryBoards() {
  const location = useLocation();
  const peerPage = isPeerPage(location);

  const isSM = useMediaQuery((theme) => {
    return location.pathname.includes('/board-directory')
      ? theme.breakpoints.down([1272])
      : theme.breakpoints.down('sm');
  });
  const { asLoggedUserDetails, isImpersonated } = useAccount();
  const [doNotFetch, setDoNotFetch] = useState(false);
  const classes = useStyles(isSM);

  const { t } = useTranslation();
  function getQueryParam() {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      return queryParams.get('keyword') || '';
    }
    return '';
  }

  const boardMeetingsPage = location.pathname === '/board-meetings';

  const [queryParameters, setQueryParameters] = useState({
    ADVISOR_ID: asLoggedUserDetails.userId,
    SEARCH_STRING: getQueryParam(),
    LIMIT: EndpointParameterFilters.COMPANIES_FETCH_LIMIT,
    OFFSET: 0,
    UPCOMING_BOARD_MEETINGS_ONLY: boardMeetingsPage,
    PEER_FILTER: peerPage ? 'P2P' : 'Company',
  });

  function handleSearch(props) {
    const { keywords, skills, industries } = props;
    const searchString = {
      ADVISOR_ID: asLoggedUserDetails.userId,
      LIMIT: EndpointParameterFilters.COMPANIES_FETCH_LIMIT,
      OFFSET: 0,
      PEER_FILTER: peerPage ? 'P2P' : 'Company',
    };

    if (keywords && keywords.length > 0) {
      searchString.SEARCH_STRING = keywords;
    }
    if (!isNil(skills) && skills[0]) {
      searchString.SKILLS_TAGS_IDS = skills.map((skill) => skill.id);
    }
    if (!isNil(industries) && industries[0]) {
      searchString.INDUSTRY_TAGS_IDS = industries.map(
        (industry) => industry.id
      );
    }

    setDoNotFetch(false);
    setQueryParameters(searchString);
  }

  useEffect(() => {
    setQueryParameters((prevParams) => ({
      ...prevParams,
      SEARCH_STRING: getQueryParam(),
    }));
  }, [location.search]);

  return (
    <div style={classes.container}>
      <AdvisoryBoardsHeader peerPage={peerPage} />
      {!peerPage && isImpersonated && (
        <CompaniesFilter onSearchButtonClicked={handleSearch} />
      )}

      <SceneContentStateSelector
        helmetTitle={t('BOARD_DIRECTORY_HELMET_TITLE')}
        endPointAppSyncName={EndpointResponseObjectName.SEARCH_COMPANIES}
        endPointCall={searchCompanies}
        endPointCallParameters={queryParameters}
        ContentTag={AdvisorSearchCompanies}
        doNotShowEmptyStateScene
        doNotFetch={doNotFetch}
        isBoardSekeleton
        noMaxWidth
        setFullScreen
        contentProps={{
          emptySearchParams:
            !queryParameters.SEARCH_STRING &&
            (!queryParameters.SKILLS_TAGS_IDS ||
              queryParameters.SKILLS_TAGS_IDS?.length === 0) &&
            (!queryParameters.INDUSTRY_TAGS_IDS ||
              queryParameters.INDUSTRY_TAGS_IDS?.length === 0),
        }}
      />
    </div>
  );
}
export default AdvisoryBoards;
