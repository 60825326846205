import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Divider, useMediaQuery, Menu, Box, Typography } from '@mui/material';
import {
  EditOutlined,
  Launch,
  FavoriteBorder,
  TrendingUp,
} from '@mui/icons-material';
import { ReactComponent as Rocket } from '../../../images/charm-rocket.svg';
import { useAccount } from './../../../contexts/Account';
import eventBus, { eventBusValues } from './../../../eventBus';
import { getAdvisorQueryParams } from '../../../utils/queryParams';

const useStyles = () => ({
  container: {
    width: '410px',
    height: '520px',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
  },
  menuItemsContainer: {
    width: '330px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 10px rgba(230, 237, 254, 0.25)',
    border: '1px solid #F5F7FA',
    borderRadius: '12px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
  },
  itemIcon: {
    marginLeft: '17px',
    marginRight: '11px',
    fontSize: '23px',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#232b35',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingBottom: '4px',
    paddingTop: '6px',
    paddingRight: '20px',
    paddingLeft: '20px',
    marginRight: '-20px',
    marginLeft: '-20px',
    width: '310px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  menuItemLogout: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingBottom: '4px',
    paddingTop: '6px',
    marginLeft: '13px',
    color: '#232b35',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    width: '260px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
  },
  divider: {
    width: '94%',
    marginTop: '10px',
    marginBottom: '10px',
    color: '#F5F7FA',
  },
});

function AdvisorMenu(props) {
  const { hideMenu, anchorEl, open } = props;
  const { accountDetails, signOut, currentPlan, isFreeTrial, isFreemium } =
    useAccount();

  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  function handleMenuItem(url, newTab = false) {
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  }

  async function onLogoutClick() {
    await signOut();
  }

  function navigateInApp(path) {
    history.push(path);
  }

  function helpCenterClick() {
    const url = 'https://advisorycloud.com/whatsnew/';
    const params = `member_type=advisor&email=${accountDetails.email}&first_name=${accountDetails.givenName}&last_name=${accountDetails.surName}&sosfcontactid=${accountDetails.salesforceContactId}&recurly_plan_code=${accountDetails.recurlyPlanCode}&membership_tier=${currentPlan.salesforceValues.tier}`;
    window.open(`${url}?${params}`, '_blank');
  }

  function openProfile() {
    window.open(
      `${window.location.origin}/profile/${accountDetails.publicProfileUrl}`,
      '_blank'
    );
  }

  function getOptions() {
    return (
      <div style={classes.menuItemsContainer}>
        <button
          id="Videos&TutorialsMenuOption"
          style={classes.menuItem}
          onClick={() =>
            window.location.replace(
              `https://advisorycloud.com/help?${getAdvisorQueryParams({
                accountDetails,
                isFreeTrial,
                isFreemium,
              })}`
            )
          }
          type="button"
        >
          <Launch sx={classes.itemIcon} />
          Videos & Tutorials
        </button>
        <Divider sx={classes.divider} />

        {/* <button
          id="helpCenterMenuOption"
          style={classes.menuItem}
          onClick={() => {
            helpCenterClick();
          }}
          type="submit"
        >
          <HelpOutlineRounded sx={classes.itemIcon} />
          {t('HELP-CENTER-MENU-ITEM-TEXT')}
        </button> */}
        {accountDetails.upgradeUrl && (
          <button
            id="upgradeMenuOption"
            style={classes.menuItem}
            onClick={() =>
              handleMenuItem(
                `${accountDetails.upgradeUrl}?iwm_advisor_id=${accountDetails.iwmId}&sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.email}&plancode=${accountDetails.recurlyPlanCode}`,
                true
              )
            }
            type="submit"
          >
            <TrendingUp sx={classes.itemIcon} />
            {t('UPGRADE-MEMBERSHIP-MENU-ITEM-TEXT')}
          </button>
        )}
        <button
          id="settingsMenuOption"
          style={classes.menuItem}
          onClick={() => navigateInApp('/account')}
          type="submit"
        >
          <FavoriteBorder sx={classes.itemIcon} />

          {t('MEMBERSHIP-SETTINGS-MENU-ITEM-TEXT')}
        </button>
        <button
          id="buildBoardMenuOption"
          style={{
            ...classes.menuItem,
            paddingRight: '0px',
          }}
          onClick={() =>
            window.location.assign(
              `https://advisorycloud.com/add-a-board-to-your-membership?${getAdvisorQueryParams(
                { accountDetails, isFreeTrial, isFreemium }
              )}`,
              '_blank'
            )
          }
          type="submit"
        >
          <Box
            sx={{
              backgroundColor: '#D7F1FD',
              display: 'flex',
              paddingRight: '14px',
              alignItems: 'center',
              height: '68px',
              borderRadius: '12px',
              paddingLeft: '12px',
              width: '100%',
            }}
          >
            <Rocket
              style={{
                ...classes.itemIcon,
                marginLeft: '6px',
                marginBottom: '8px',
              }}
            />
            <Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '500',
                  lineHeight: '32px',
                  letterSpacing: '-0.25px',
                  textAlign: 'left',
                  color: '#232B35',
                  height: '18px',
                  marginTop: '10px',
                }}
              >
                Build your own Advisory Board
              </Typography>
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: '400',
                  lineHeight: '32px',
                  letterSpacing: '-0.25px',
                  textAlign: 'left',
                  color: '#1D95CD',
                  marginTop: '4px',
                }}
              >
                Comes free with your membership!
              </Typography>
            </Box>
          </Box>
        </button>
        <Divider sx={classes.divider} />

        <button
          id="advisorLogoutMenuOption"
          style={classes.menuItemLogout}
          type="submit"
          onClick={onLogoutClick}
        >
          {t('LOGOUT-MENU-ITEM-TEXT')}
        </button>
      </div>
    );
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      sx={classes.container}
      elevation={0}
      onMouseLeave={() => {
        if (!isSM) {
          hideMenu();
        }
      }}
      onClose={hideMenu}
    >
      {getOptions()}
    </Menu>
  );
}
export default AdvisorMenu;
