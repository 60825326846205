import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CompanyContact from './../Contact/CompanyContact';
import Tag from './../../Tag';
import { isNil } from './../../../services/utils';
import { isDateExpired, formatBoardJoinedDate } from './../../../services/date';
import { AccountType } from './../../../services/utils/types';
import CompanyBoardPreview from './../BoardPreview';
import CompanySlideHeader from './CompanySlideHeader';
import CustomIcon from './../../../icons/customIcon';
import { useAccount } from './../../../contexts/Account';
import DefaultAdvisorAvatar from './../../../icons/user-avatar.svg';
import AcPlusPreview from './../BoardPreview/AcPlusPreview';
import Treatment from '../../Treatment';
import { featureFlags } from '../../../splitSetup';
import { ReactComponent as ConfettiIcon } from './assets/confetti.svg';
import BoardJoinButton from '../../BoardJoinButton';
import Conversation from './assets/conversation.svg';
import JSConfetti from 'js-confetti';
import { ReactComponent as LeaveBoardIcon } from './assets/leave_board_icon.svg';
import { resignFromBoard } from '../../../services/advisor_services';
import { useHistory, useLocation } from 'react-router-dom';
import {
  companyFetchAllPostsCall,
  deleteBoardRoomAppleCalendar,
  deleteBoardRoomGoogleCalendar,
  manageAppleCalendar,
  manageGoogleCalendar,
} from '../../../services/company_services';

const useStyles = (isSM, disabledCompany) => ({
  card: {
    width: '100%',
    overflowY: disabledCompany ? 'hidden' : null,
    paddingLeft: !isSM ? 0 : 2,
    paddingRight: !isSM ? 0 : 2,
  },
  companyInformationSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: isSM ? '0px' : '40px',
    paddingRight: isSM ? '0px' : '20px',
    paddingTop: isSM ? '0px' : 'unset',
    width: '100%',
    paddingBottom: isSM ? '0px' : '20px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainInformationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  companyName: {
    marginTop: '20px',
    marginBottom: '30px',
  },
  tagsTitle: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '20px',
    color: '#232B35',
    marginTop: '30px',
    marginBottom: '15px',
  },
  boardMeetingUpperBarText: {
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    fontSize: '90%',
  },
  generalMargin: {
    marginRight: '8px',
    marginBottom: '5px',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  boardsContainer: {
    '& > *': {},
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  biographyText: {
    whiteSpace: 'pre-wrap',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 400,
  },
  boardMeetingUpperBar: {
    width: '100%',
    backgroundColor: '#7F00FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    height: !isSM ? '85px' : '40%',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    padding: isSM ? '15px' : '10px',
    marginBottom: '20px',
  },
  p2pUpperBar: {
    width: '100%',
    backgroundColor: '#24A0A3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    height: '50px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    padding: isSM ? '15px' : '20px',
  },
  benefitsText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#646D7A',
  },
  benefitsContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    justifyContent: 'left',
    marginTop: '20px',
    marginBottom: '10px',
  },
  benefitItem: {
    flex: 1,
    overflow: 'hidden',
    border: '1px solid #EFF0F7',
    borderRadius: '12px',
    marginRight: isSM ? '0px' : '1rem',
    marginBottom: isSM ? '20px' : '0px',
    maxWidth: '500px',
    padding: '24px',
  },
  benefitsDetailsText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#646D7A',
  },
  benefitsCategoryText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
    whiteSpace: 'nowrap',
    marginBottom: '11px',
  },
  newBoardMeetingUpperBarText: {
    letterSpacing: '-0.43px',
    lineHeight: '24px',
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    fontSize: '0.9rem',
  },
  imageContainerOwnProfile: {
    width: isSM ? '60px' : '70px',
    height: isSM ? '60px' : '70px',
    margin: isSM ? 'auto' : '0 10px 0 0',
  },
  dialogContainer: {
    padding: '15px',
  },
  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
  },
});

const categoryOptions = [
  {
    text: 'Compensation Potential',
    icon: 'benefits-compensation',
  },
  {
    text: 'Equity Potential',
    icon: 'benefits-equity',
  },
  {
    text: 'Formal Roles',
    icon: 'benefits-roles',
  },
  {
    text: 'Perks',
    icon: 'benefit-perk',
  },
];

const CompanyOpportunitySlide = (props) => {
  let {
    company,
    skills,
    companyMembers,
    handleClickAway,
    hidePostSection,
    isFreemiumCompany,
    isAcPlusCompany,
    invitation,
  } = props;

  const canvas = document.getElementById('custom_canvas');
  const jsConfetti = new JSConfetti({ canvas: canvas });

  const {
    type,
    isFreemium,
    accountDetails,
    currentUserInfo,
    asLoggedUserDetails,
  } = useAccount();
  const p2pFlag = company.boardType == 'P2P';
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down([1230]));
  const disabledCompany =
    company.opportunityStage === 'dormant' ||
    company.opportunityStage === 'pending_cancellation' ||
    company.boardOwnerStatus === 'inactive' ||
    company.boardOwnerStatus === 'paused';
  const classes = useStyles(isSM, disabledCompany);
  const [companyCEO, setCompanyCEO] = useState('');
  const [sender, setSender] = useState({});
  const [showUpperBar] = useState(
    (company.upcomingBoardMeeting &&
      !isDateExpired(company.upcomingBoardMeeting)) ||
      company.boardType == 'P2P'
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [isJoined, setIsJoined] = useState(
    accountDetails.boards.memberBoards.some((bc) => bc.id === company.id)
  );
  const advisorId = accountDetails?.id;
  const calendarTokens = accountDetails?.calendarTokens || {};
  const googleToken = calendarTokens.google?.isSync;
  const appleToken = calendarTokens.apple?.isSync;

  let featuredBenefits = [];
  if (company.benefits && company.benefits.length) {
    featuredBenefits = company.benefits.filter((b) => b.featured).slice(0, 3);
  }

  useEffect(() => {
    if (companyMembers.length > 0) {
      setSender(
        companyMembers.find((member) => member.id === invitation.sourceUserId)
      );
      setCompanyCEO(companyMembers[0]);
    }
  }, [companyMembers]);

  function renderCompanyBiographyText() {
    if (!company) return;
    if (!company.storyAndMission) return;
    return (
      <>
        <Typography variant="body1" sx={classes.biographyText}>
          {company.storyAndMission}
        </Typography>
      </>
    );
  }

  const desiredSkills = company.desiredSkillsTagIds
    ? company.desiredSkillsTagIds
    : [];

  skills = skills
    ? skills.filter((skill) => desiredSkills.includes(skill.id))
    : [];

  function conditionallyRenderUpperBar() {
    if (isAcPlusCompany && type == AccountType.ADVISOR) {
      const isInvited =
        accountDetails.boards &&
        accountDetails.boards.invitedBoards &&
        accountDetails.boards.invitedBoards.some((bc) => bc.id === company.id);
      if (isInvited) {
        return (
          <div style={classes.boardMeetingUpperBar}>
            <Typography variant="body1" sx={classes.boardMeetingUpperBarText}>
              Accepting this invitation does not affect your total available
              board seats on your current membership
            </Typography>
          </div>
        );
      }
    }
    if (company.upcomingBoardMeeting) {
      return !isDateExpired(company.upcomingBoardMeeting) ? (
        <div style={classes.boardMeetingUpperBar}>
          <Typography variant="body1" sx={classes.boardMeetingUpperBarText}>
            {getUpcomingBoardMeetingValue(company.upcomingBoardMeeting, true)}
          </Typography>
        </div>
      ) : null;
    }
    if (p2pFlag) {
      return (
        <div style={classes.p2pUpperBar}>
          <Typography variant="body1" sx={classes.boardMeetingUpperBarText}>
            Joining a Peer Advisor Board will not count towards the number of
            board seats provided by your membership
          </Typography>
        </div>
      );
    }
  }

  function getUpcomingBoardMeetingValue(
    upcomingBoardMeeting,
    returnString = false
  ) {
    if (!isNil(upcomingBoardMeeting)) {
      if (!isDateExpired(upcomingBoardMeeting)) {
        if (returnString) {
          const meetingDate = new Date(upcomingBoardMeeting);
          const meetingText = `Join us for our next Board Meeting coming up on ${formatBoardJoinedDate(
            meetingDate
          )}. New advisors welcome!`;
          return meetingText;
        }
        return true;
      }
    }
    return false;
  }

  function getIconByText(text) {
    const option = categoryOptions.find((option) => option.text === text);
    return option ? option.icon : '';
  }

  function formatDetails(text) {
    var urlPattern = /(https?:\/\/[^\s]+)/g;
    var cleanedText = text.replace(urlPattern, '');

    cleanedText = cleanedText.substring(0, 250);

    return cleanedText;
  }

  function getBioTitle() {
    if (isAcPlusCompany && (!company || !company.storyAndMission)) {
      return '';
    }
    if (p2pFlag) {
      return t('ABOUT-PEER-LABEL');
    }
    return t('ABOUT-US-LABEL');
  }

  //TODO: move to a separate utils file if it's gonna be used elsewhere
  function throwConfetti() {
    jsConfetti.addConfetti();
  }

  function resignAction() {
    setIsModalLoading(true);
    resignFromBoard({
      ADVISOR_ID: asLoggedUserDetails.userId,
      COMPANY_ID: company.id,
    })
      .then(async () => {
        if (
          accountDetails.viewedBoards &&
          accountDetails.viewedBoards.length > 0
        ) {
          accountDetails.viewedBoards = accountDetails.viewedBoards.filter(
            (board) => board != company.id
          );
        }
        const newMemberBoards = [
          ...asLoggedUserDetails.advisorRelatedCompanies.memberBoards.filter(
            (element) => element.slug !== company.slug
          ),
        ];
        const nonRefMemberBoards = newMemberBoards.map((el) => ({ ...el }));
        accountDetails.boards.memberBoards = nonRefMemberBoards;
        asLoggedUserDetails.advisorRelatedCompanies.memberBoards =
          nonRefMemberBoards;

        try {
          if (googleToken) {
            console.log(`Unsyncing Google Calendar for post`);
            await deleteBoardRoomGoogleCalendar({
              companyId: company.id,
              advisorId: accountDetails.id,
              token: calendarTokens.google.token,
            });
          }

          if (appleToken) {
            console.log(`Unsyncing Apple Calendar for post`);
            await deleteBoardRoomAppleCalendar({
              companyId: company.id,
              advisorId: accountDetails.id,
              email: calendarTokens.apple.email,
              password: calendarTokens.apple.password,
            });
          }
        } catch (error) {
          console.error(`Error syncing calendars for post:`, error.message);
        }

        if (location.pathname === '/dashboard') {
          window.location.reload();
        } else {
          history.push({
            pathname: '/dashboard',
            state: {
              resignedCompany: company.displayName,
            },
          });
        }

        setIsModalLoading(false);
        setIsModalVisible(false);
        handleClickAway();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function LeaveBoardDialog() {
    return (
      <Dialog
        open={isModalVisible}
        sx={classes.dialogContainer}
        maxWidth="sm"
        onClose={() => {
          if (!isModalLoading) {
            setIsModalVisible(false);
          }
        }}
      >
        <div style={classes.dialogContainer}>
          <DialogContent>
            <DialogContentText>
              <Typography
                variant="caption"
                style={{ fontFamily: 'Poppins', fontSize: 16 }}
              >
                {t('ADVISOR-BOARDROOM-MODAL-RESIGN')}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={classes.dialogActions}>
            {isModalLoading ? (
              <div style={classes.modalLoadingContainer}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsModalVisible(false);
                  }}
                  style={{
                    paddingTop: 15,
                    paddingBottom: 15,
                    fontFamily: 'Poppins',
                    height: 37,
                    borderRadius: 20,
                  }}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => resignAction()}
                  style={{
                    paddingTop: 15,
                    paddingBottom: 15,
                    margin: 0,
                    fontFamily: 'Poppins',
                    height: 37,
                    borderRadius: 20,
                  }}
                >
                  {t('ADVISOR-BOARDROOM-LEFT-MENU-RESIGN')}
                </Button>
              </>
            )}
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  function oldCompanyOpportunirySlide() {
    return (
      <>
        <canvas
          id="custom_canvas"
          style={{
            position: 'fixed',
            zIndex: 99999,
            display: 'hide',
            height: '100dvh',
            width: '100%',
            pointerEvents: 'none',
          }}
        ></canvas>
        {invitation.status === 'pending' ? (
          <div style={classes.boardMeetingUpperBar}>
            <ConfettiIcon
              cursor={'pointer'}
              color="hsla(0, 0%, 100%, 1)"
              transform="scale(0.9)"
              style={{ marginRight: '10px', pointerEvents: 'none' }}
            />
            <div style={{ maxWidth: '80%', textAlign: 'justify' }}>
              <Typography
                variant="body1"
                sx={classes.newBoardMeetingUpperBarText}
              >
                You have received this invitation due to your profession,
                expertise and other characteristics this board would find
                valuable. Please note, companies are looking for new and diverse
                opinions which is why some invitations may be outside your core
                area of expertise.
              </Typography>
            </div>
          </div>
        ) : (
          conditionallyRenderUpperBar()
        )}
        {disabledCompany && (
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              zIndex: '10',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                backgroundColor: '#F9F9F9',
                opacity: '0.7',
                zIndex: '-1',
              },
            }}
          >
            <Box
              sx={{
                backgroundColor: 'black',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '14px',
                position: 'relative',
                borderRadius: '30px',
              }}
            >
              <Typography color="white" sx={{ fontSize: '16px' }}>
                This company is not currently accepting new advisors.
              </Typography>
            </Box>
          </Box>
        )}
        <Paper sx={classes.card}>
          <div style={classes.companyInformationSectionContainer}>
            <div style={classes.mainInformationContainer}>
              <CompanySlideHeader
                invitation={invitation}
                handleClickAway={handleClickAway}
                company={company}
                showUpperBar={showUpperBar}
                companyCEO={companyCEO}
                p2pFlag={p2pFlag}
                isFreemiumCompany={isFreemiumCompany}
                isAcPlusCompany={isAcPlusCompany}
              />
            </div>

            <Typography variant="body1" sx={{ ...classes.tagsTitle }}>
              {getBioTitle()}
            </Typography>
            {renderCompanyBiographyText()}
            {!p2pFlag ? (
              <>
                <Typography variant="body1" sx={{ ...classes.tagsTitle }}>
                  {t('LEADERSHIP-LABEL')}
                </Typography>
                <div style={classes.boardsContainer}>
                  {companyMembers ? (
                    <>
                      {companyMembers.map((member) => (
                        <Box className={classes.generalMargin} key={member.id}>
                          <CompanyContact
                            contact={member}
                            customAvatar={() => (
                              <Avatar
                                sx={{ width: '40px', height: '40px' }}
                                src={
                                  member.contactLogoId
                                    ? member.contactLogoLocation
                                    : DefaultAdvisorAvatar
                                }
                              />
                            )}
                          />
                        </Box>
                      ))}
                    </>
                  ) : null}
                </div>
              </>
            ) : null}
            {!p2pFlag && !isFreemiumCompany && !isFreemium && (
              <>
                {isAcPlusCompany && (!skills || skills.length == 0) ? null : (
                  <Typography variant="body1" sx={classes.tagsTitle}>
                    {t('SEEKING-GUIDANCE-ON-LABEL')}
                  </Typography>
                )}
                <div className="skillTagBlock" style={classes.tagsContainer}>
                  {skills.map((skill, index) => (
                    <Box sx={classes.generalMargin} key={index}>
                      <Tag label={skill.label} />
                    </Box>
                  ))}
                </div>
              </>
            )}
            {featuredBenefits && featuredBenefits.length ? (
              <div id="benefitsSection">
                <Typography variant="body1" sx={classes.tagsTitle}>
                  {t('BENEFITS-LABEL')}
                </Typography>
                <Typography variant="body1" sx={classes.benefitsText}>
                  {t('BENEFITS-DESCRIPTION', {
                    companyName: company.displayName,
                  })}
                </Typography>
                <div style={classes.benefitsContainer}>
                  {featuredBenefits &&
                    featuredBenefits.map((benefit) => (
                      <div
                        className="featureBenefitItem"
                        style={classes.benefitItem}
                      >
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{ marginRight: '8px', marginTop: '-5px' }}
                          >
                            <CustomIcon
                              iconname={getIconByText(benefit.category)}
                              color="primary"
                              fontSize="small"
                              style={{
                                color: '#232B35',
                                display: 'inline',
                              }}
                            />
                          </div>
                          <Typography sx={classes.benefitsCategoryText}>
                            {benefit.category}
                          </Typography>
                        </div>
                        <Typography sx={classes.benefitsDetailsText}>
                          {formatDetails(benefit.details)}
                        </Typography>
                      </div>
                    ))}
                </div>
              </div>
            ) : null}
          </div>
          {!hidePostSection &&
            type === AccountType.ADVISOR &&
            !isFreemiumCompany &&
            !isAcPlusCompany && (
              <CompanyBoardPreview
                invitation={invitation}
                company={company}
                companyId={company.id}
                companyName={company.displayName}
                companyImage={`https://${company.image.location}`}
                companyCEO={companyCEO}
              />
            )}
          {!hidePostSection &&
            type === AccountType.ADVISOR &&
            isAcPlusCompany && (
              <AcPlusPreview
                company={company}
                companyId={company.id}
                companyName={company.displayName}
                companyImage={`https://${company.image.location}`}
                companyCEO={companyCEO}
              />
            )}
          {isJoined ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginBottom: !isSM ? 35 : 100,
                marginRight: 30,
                alignItems: 'start',
              }}
            >
              {!(isAcPlusCompany || isFreemiumCompany) ? (
                <Button
                  onClick={() => setIsModalVisible(true)}
                  sx={{
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                  style={{
                    marginTop: '30px',
                    fontWeight: 400,
                    fontSize: '14px',
                    textTransform: 'none',
                    fontStyle: 'normal',
                    lineHeight: '24px',
                    letterSpacing: '-0.43px',
                    color: '#646D7A',
                  }}
                  disableRipple
                >
                  <LeaveBoardIcon
                    style={{
                      marginRight: '4px',
                      color: 'red',
                      height: '16.5px',
                    }}
                    iconname="boardroom_icon"
                  />
                  Leave board
                </Button>
              ) : (
                <Button
                  onClick={() => setIsModalVisible(true)}
                  sx={classes.subMenuButton}
                  style={{
                    marginTop: '30px',
                    fontWeight: 400,
                    fontSize: '14px',
                    textTransform: 'none',
                    fontStyle: 'normal',
                    lineHeight: '24px',
                    letterSpacing: '-0.43px',
                    color: '#646D7A',
                  }}
                  disableRipple
                >
                  <LeaveBoardIcon
                    style={{
                      marginRight: '4px',
                      color: 'red',
                      height: '16.5px',
                    }}
                    iconname="boardroom_icon"
                  />
                  Leave this AdvisoryCloud
                </Button>
              )}
            </div>
          ) : null}
          {LeaveBoardDialog()}
        </Paper>
      </>
    );
  }

  function newCompanyOpportunirySlide() {
    return (
      <>
        <canvas
          id="custom_canvas"
          style={{
            position: 'fixed',
            zIndex: 99999,
            display: 'hide',
            height: '100dvh',
            width: '100%',
            pointerEvents: 'none',
          }}
        ></canvas>
        {invitation.status === 'pending' ? (
          <div style={classes.boardMeetingUpperBar}>
            <ConfettiIcon
              cursor={'pointer'}
              color="hsla(0, 0%, 100%, 1)"
              transform="scale(0.9)"
              style={{ marginRight: '10px', pointerEvents: 'none' }}
            />
            <div style={{ maxWidth: '80%', textAlign: 'justify' }}>
              <Typography
                variant="body1"
                sx={classes.newBoardMeetingUpperBarText}
              >
                You have received this invitation due to your profession,
                expertise and other characteristics this board would find
                valuable. Please note, companies are looking for new and diverse
                opinions which is why some invitations may be outside your core
                area of expertise.
              </Typography>
            </div>
          </div>
        ) : (
          conditionallyRenderUpperBar()
        )}
        {disabledCompany && (
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              zIndex: '10',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                backgroundColor: '#F9F9F9',
                opacity: '0.7',
                zIndex: '-1',
              },
            }}
          >
            <Box
              sx={{
                backgroundColor: 'black',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '14px',
                position: 'relative',
                borderRadius: '30px',
              }}
            >
              <Typography color="white" sx={{ fontSize: '16px' }}>
                This company is not currently accepting new advisors.
              </Typography>
            </Box>
          </Box>
        )}
        <Paper sx={classes.card}>
          <div style={classes.companyInformationSectionContainer}>
            <div style={classes.mainInformationContainer}>
              <CompanySlideHeader
                launchConfetti={throwConfetti}
                featureFlag={true}
                invitation={invitation}
                handleClickAway={handleClickAway}
                company={company}
                showUpperBar={showUpperBar}
                companyCEO={companyCEO}
                p2pFlag={p2pFlag}
                isFreemiumCompany={isFreemiumCompany}
                isAcPlusCompany={isAcPlusCompany}
              />
            </div>
            {invitation.note && (
              //TODO: move style to a class
              <div
                style={{
                  display: 'flex',
                  width: isSM ? '90dvw' : '80%',
                  backgroundColor: 'hsla(216, 33%, 97%, 1)',
                  alignSelf: 'center',
                  justifySelf: 'center',
                  marginTop: '2.5rem',
                  marginRight: isSM ? '0px' : '20%',
                  borderRadius: '25px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '2rem',
                    width: '100%',
                    rowGap: '20px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                  }}
                >
                  {/* container title row */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      columnGap: '2%',
                      alignItems: 'center',
                    }}
                  >
                    <ConfettiIcon
                      cursor={'pointer'}
                      color="hsla(255, 95%, 60%, 1)"
                      transform="scale(0.9)"
                      onClick={throwConfetti}
                    />
                    <Typography
                      variant="h4"
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        fontSize: '20px',
                        lineHeight: '24px',
                        letterSpacing: '-0.43px',
                        color: 'hsla(213, 20%, 17%, 1)',
                        height: 'fit-content',
                      }}
                    >
                      You’re invited to join our advisory board!
                    </Typography>
                  </div>
                  {/* Body row */}
                  <div
                    style={{
                      display: 'flex',
                      width: '95%',
                      backgroundColor: 'hsla(0, 0%, 100%, 1)',
                      border: '1px solid hsla(255, 95%, 60%, 0.5)',
                      borderRadius: '25px',
                      borderBottomLeftRadius: 'unset',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        justifyContent: 'flex-start',
                        margin: '15px',
                        letterSpacing: '-0.43px',
                        lineHeight: '24px',
                        color: 'hsla(213, 20%, 17%, 1)',
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: 500,
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {invitation.note}
                    </Typography>
                  </div>
                  {/* Bottom row */}
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Avatar
                      sx={classes.imageContainerOwnProfile}
                      src={
                        sender?.contactLogoId
                          ? sender?.contactLogoLocation
                          : DefaultAdvisorAvatar
                      }
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        rowGap: '10px',
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          letterSpacing: '-0.43px',
                          fontWeight: 600,
                          fontSize: '15px',
                        }}
                      >
                        {sender?.displayName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'hsla(217, 10%, 44%, 1)',
                          letterSpacing: '-0.43px',
                          fontWeight: 600,
                          fontSize: '15px',
                        }}
                      >
                        {sender?.title}
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: 'inline-flex',
                        marginLeft: 'auto',
                        marginRight: '5%',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          width: '2.5rem',
                          height: '2.2rem',
                          backgroundColor: 'hsla(233, 30%, 95%, 1)',
                          borderRadius: '50px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={Conversation}
                          style={{ cursor: 'pointer' }}
                          alt="conversations"
                          onClick={() => {
                            window.location.replace(
                              `${window.location.origin}/messages`
                            );
                          }}
                        />
                      </div>
                      {!isSM ? (
                        <BoardJoinButton
                          featureFlag={true}
                          launchConfetti={throwConfetti}
                          sx={{
                            button: {
                              minWidth: '0px',
                              width: '11rem',
                              marginBottom: '0px',
                            },
                            container: { marginLeft: 'auto' },
                          }}
                          invitation={invitation}
                          boardId={company.id}
                          company={company}
                          companyCEO={companyCEO}
                          staticClass="joinButtonTop"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Typography variant="body1" sx={{ ...classes.tagsTitle }}>
              {getBioTitle()}
            </Typography>
            {renderCompanyBiographyText()}
            {!p2pFlag ? (
              <>
                <Typography variant="body1" sx={{ ...classes.tagsTitle }}>
                  {t('LEADERSHIP-LABEL')}
                </Typography>
                <div style={classes.boardsContainer}>
                  {companyMembers ? (
                    <>
                      {companyMembers.map((member) => (
                        <Box className={classes.generalMargin} key={member.id}>
                          <CompanyContact
                            contact={member}
                            customAvatar={() => (
                              <Avatar
                                sx={{ width: '40px', height: '40px' }}
                                src={
                                  member.contactLogoId
                                    ? member.contactLogoLocation
                                    : DefaultAdvisorAvatar
                                }
                              />
                            )}
                          />
                        </Box>
                      ))}
                    </>
                  ) : null}
                </div>
              </>
            ) : null}
            {!p2pFlag && !isFreemiumCompany && !isFreemium && (
              <>
                {isAcPlusCompany && (!skills || skills.length == 0) ? null : (
                  <Typography variant="body1" sx={classes.tagsTitle}>
                    {t('SEEKING-GUIDANCE-ON-LABEL')}
                  </Typography>
                )}
                <div className="skillTagBlock" style={classes.tagsContainer}>
                  {skills.map((skill, index) => (
                    <Box sx={classes.generalMargin} key={index}>
                      <Tag label={skill.label} />
                    </Box>
                  ))}
                </div>
              </>
            )}
            {featuredBenefits && featuredBenefits.length ? (
              <div id="benefitsSection">
                <Typography variant="body1" sx={classes.tagsTitle}>
                  {t('BENEFITS-LABEL')}
                </Typography>
                <Typography variant="body1" sx={classes.benefitsText}>
                  {t('BENEFITS-DESCRIPTION', {
                    companyName: company.displayName,
                  })}
                </Typography>
                <div style={classes.benefitsContainer}>
                  {featuredBenefits &&
                    featuredBenefits.map((benefit) => (
                      <div
                        className="featureBenefitItem"
                        style={classes.benefitItem}
                      >
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{ marginRight: '8px', marginTop: '-5px' }}
                          >
                            <CustomIcon
                              iconname={getIconByText(benefit.category)}
                              color="primary"
                              fontSize="small"
                              style={{
                                color: '#232B35',
                                display: 'inline',
                              }}
                            />
                          </div>
                          <Typography sx={classes.benefitsCategoryText}>
                            {benefit.category}
                          </Typography>
                        </div>
                        <Typography sx={classes.benefitsDetailsText}>
                          {formatDetails(benefit.details)}
                        </Typography>
                      </div>
                    ))}
                </div>
              </div>
            ) : null}
          </div>
          {!hidePostSection &&
            type === AccountType.ADVISOR &&
            !isFreemiumCompany &&
            !isAcPlusCompany && (
              <CompanyBoardPreview
                invitation={invitation}
                company={company}
                companyId={company.id}
                companyName={company.displayName}
                companyImage={`https://${company.image.location}`}
                companyCEO={companyCEO}
              />
            )}
          {!hidePostSection &&
            type === AccountType.ADVISOR &&
            isAcPlusCompany && (
              <AcPlusPreview
                company={company}
                companyId={company.id}
                companyName={company.displayName}
                companyImage={`https://${company.image.location}`}
                companyCEO={companyCEO}
              />
            )}
          {isJoined ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginBottom: !isSM ? 35 : 100,
                marginRight: 30,
                alignItems: 'start',
              }}
            >
              {!(isAcPlusCompany || isFreemiumCompany) ? (
                <Button
                  onClick={() => setIsModalVisible(true)}
                  sx={{
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                  style={{
                    marginTop: '30px',
                    fontWeight: 400,
                    fontSize: '14px',
                    textTransform: 'none',
                    fontStyle: 'normal',
                    lineHeight: '24px',
                    letterSpacing: '-0.43px',
                    color: '#646D7A',
                  }}
                  disableRipple
                >
                  <LeaveBoardIcon
                    style={{
                      marginRight: '4px',
                      color: 'red',
                      height: '16.5px',
                    }}
                    iconname="boardroom_icon"
                  />
                  Leave board
                </Button>
              ) : (
                <Button
                  onClick={() => setIsModalVisible(true)}
                  sx={classes.subMenuButton}
                  style={{
                    marginTop: '30px',
                    fontWeight: 400,
                    fontSize: '14px',
                    textTransform: 'none',
                    fontStyle: 'normal',
                    lineHeight: '24px',
                    letterSpacing: '-0.43px',
                    color: '#646D7A',
                  }}
                  disableRipple
                >
                  <LeaveBoardIcon
                    style={{
                      marginRight: '4px',
                      color: 'red',
                      height: '16.5px',
                    }}
                    iconname="boardroom_icon"
                  />
                  Leave this AdvisoryCloud
                </Button>
              )}
            </div>
          ) : null}

          {LeaveBoardDialog()}
        </Paper>
      </>
    );
  }
  return (
    <Treatment
      userId={currentUserInfo.username}
      splitNames={featureFlags.itj_advisor}
      updateOnSdkTimedout
      conditionFlag
      evaluatedComponent={newCompanyOpportunirySlide()}
      conditionFailedComponent={oldCompanyOpportunirySlide()}
    />
  );
};

export default CompanyOpportunitySlide;
