import React, { useState } from 'react';
import { Typography, useMediaQuery, Box, OutlinedInput } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import OopsWindow from './Oops';
import LoadingIndicatorLogo from '../components/LoadingIndicatorLogo';
import ACSnackbar from '../components/ACSnackbar';
import PasswordForm from '../components/PasswordSet/PasswordForm';
import UnhandledError from '../components/PasswordSet/UnhandledError';
import ExpiredLink from '../components/PasswordSet/ExpiredError';
import LoginError from '../components/PasswordSet/LoginError';
import {
  forgotPassword,
  forgotPasswordSubmit,
  logIn,
} from '../services/Authentication';
import { updateAdvisorOnboardingStep1 } from '../services/advisor_services';
import { useAccount } from '../contexts/Account';
import { AccountType } from '../services/utils/types';
import {
  ForgotPasswordResponses,
  ForgotPasswordErrorResponses,
  LoginResponseTypes,
  hasUrlParams,
} from '../services/Authentication/constants';
import PublicRegistration from '../layouts/PublicRegistration';

const useStyles = (isSM) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '12px',
    width: 'auto',
    backgroundColor: 'white',
  },
  indicatorTitle: {
    color: '#000000',
    marginBottom: '20px',
    fontSize: '28px',
    lineHeight: isSM ? '40px' : '24px',
    fontWeight: 700,
    letterSpacing: '-0.4300000071525574px',
    textAlign: 'center',
    padding: '0 12px',
  },
  indicatorText: {
    color: '#646D7A',
    marginBottom: '40px',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '-0.4300000071525574px',
    textAlign: 'center',
    maxWidth: '600px',
    padding: '0 12px',
  },
  loadingContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
  },
  codeField: {
    marginTop: '10px',
    width: isSM ? '80%' : '339px',
    marginInline: isSM ? 'auto' : null,
    marginBottom: '20px',
    minWidth: '200px',
    borderRadius: '10px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    height: '44px',
    input: {
      '&::placeholder': {
        fontSize: '14px',
      },
    },
  },
});

const SCENE_STATE_TYPES = {
  CREATE_PASSWORD: 'CREATE_PASSWORD',
  EXPIRED_LINK: 'EXPIRED_LINK',
  UNHANDLED_ERROR: 'UNHANDLED_ERROR',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SIGN_IN_LOADING: 'SIGN_IN_LOADING',
};

function PasswordVerify({ location, history }) {
  const { authenticate } = useAccount();
  const [sceneId, setSceneId] = useState(SCENE_STATE_TYPES.CREATE_PASSWORD);
  const [password, setPassword] = useState('');
  const [isErrorSnackbarVisible, setIsErrorSnackbarVisible] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isPasswordFormLoading, setIsPasswordFormLoading] = useState(false);
  const [isAbleToRetry, setIsAbleToRetry] = useState(false);
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const queryParams = new URLSearchParams(location.search);
  const b2bPage = location.pathname.includes('/password/verify-babs');

  const [email, setEmail] = useState();
  const [code, setCode] = useState('');

  async function signInProcess(data = password) {
    const response = await logIn(email, data, authenticate, true);
    if (response.response === LoginResponseTypes.LOGIN_SUCCESS) {
      if (
        response.data.signInUserSession.idToken.payload.accountType.includes(
          AccountType.ADVISOR
        )
      ) {
        history.push('/advisor/aobf');
      } else {
        history.push('/company/onboarding/step1');
      }
    }
  }

  function handleSignIn(data = password) {
    signInProcess(data);
  }

  async function handleFailedCode() {
    await forgotPassword(email, { workflow: 'signup' });
  }

  function handleForgotPasswordSubmit(result, data = password) {
    if (result === ForgotPasswordResponses.SUCCESS) {
      signInProcess(data);
    } else if (result === ForgotPasswordErrorResponses.EXPIRED_CODE) {
      setSceneId(SCENE_STATE_TYPES.EXPIRED_LINK);
      handleFailedCode();
    } else if (result === ForgotPasswordResponses.CODE_MISMATCH_EXCEPTION) {
      setSceneId(SCENE_STATE_TYPES.EXPIRED_LINK);
      handleFailedCode();
    } else {
      if (
        result === ForgotPasswordErrorResponses.LIMIT_EXCEEDED ||
        result === ForgotPasswordErrorResponses.TOO_MANY_REQUESTS
      ) {
        setIsAbleToRetry(true);
      }
      setSceneId(SCENE_STATE_TYPES.UNHANDLED_ERROR);
    }
  }

  async function handleContinueButton(data) {
    setSceneId(SCENE_STATE_TYPES.SIGN_IN_LOADING);
    setPassword(data);
    setIsPasswordFormLoading(true);
    const result = await forgotPasswordSubmit(email, code, data);
    handleForgotPasswordSubmit(result, data);
    setIsPasswordFormLoading(false);
    return result;
  }

  async function handleUnhandledErrorRetryButton() {
    const result = handleContinueButton();
    if (result !== ForgotPasswordResponses.SUCCESS) {
      setIsErrorSnackbarVisible(true);
    }
  }

  async function handleUnhandledErrorResendButton() {
    const result = await forgotPassword(email, {
      workflow: 'signup',
    });
    if (result) {
      setIsSnackbarVisible(true);
    } else {
      setIsErrorSnackbarVisible(true);
    }
  }

  function renderHelmetTranslationKey() {
    switch (sceneId) {
      case SCENE_STATE_TYPES.CREATE_PASSWORD:
        return 'PASSWORD-SET-HELMET-TITLE';
      case SCENE_STATE_TYPES.SIGN_IN_LOADING:
        return 'PASSWORD-SET-HELMET-TITLE';
      case SCENE_STATE_TYPES.LOGIN_ERROR:
        return 'ACCOUNT-LOGIN-ERROR-HELMET-TITLE';
      default:
        return 'PASSWORD-SET-ERROR-HELMET-TITLE';
    }
  }

  const [displayedComponent] = useState(() => {
    const getEmail = queryParams.get('email');
    if (getEmail !== null) {
      setEmail(queryParams.get('email').replace(/\s/g, '+'));
      return hasUrlParams.PARAMS;
    }

    return hasUrlParams.NO_PARAMS;
  });

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setCode(value);
    }
  };

  function renderScene() {
    switch (sceneId) {
      case SCENE_STATE_TYPES.CREATE_PASSWORD:
        return (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: isSM ? '100%' : null,
                alignItems: isSM ? 'center' : 'left',
              }}
            >
              <Typography
                style={{
                  color: '#646D7A',
                  fontSize: '14px',
                  fontWeight: 500,
                  letterSpacing: '-0.4300000071525574px',
                }}
              >
                Verification Code
              </Typography>
              <OutlinedInput
                id="code"
                sx={classes.codeField}
                value={code}
                inputProps={{
                  maxLength: 6,
                }}
                onChange={handleChange}
                placeholder="ex: 472642"
              />
            </Box>
            <PasswordForm
              buttonText={t('CONTINUE-BUTTON-TEXT')}
              isLoading={isPasswordFormLoading ? 1 : 0}
              isSM={isSM}
              onContinue={(pass) => handleContinueButton(pass)}
              passwordLabel={t('PASSWORD-LABEL')}
              placeholder="New password"
              disableButtonOutsideState={code.length !== 6}
              disableAutoFocus
            />
          </>
        );
      case SCENE_STATE_TYPES.EXPIRED_LINK:
        return (
          <ExpiredLink
            isSM={isSM}
            onResend={handleUnhandledErrorResendButton}
            email={email}
          />
        );
      case SCENE_STATE_TYPES.UNHANDLED_ERROR:
        return (
          <UnhandledError
            isAbleToRetry={isAbleToRetry}
            onResend={handleUnhandledErrorResendButton}
            onRetry={handleUnhandledErrorRetryButton}
            isSM={isSM}
          />
        );
      case SCENE_STATE_TYPES.SIGN_IN_LOADING:
        return (
          <div style={classes.loadingContainer}>
            <LoadingIndicatorLogo iconFontSize={133} iconRight={160} />
          </div>
        );
      case SCENE_STATE_TYPES.LOGIN_ERROR:
        return <LoginError onRetry={handleSignIn} />;

      default:
        return null;
    }
  }

  return displayedComponent === hasUrlParams.PARAMS ? (
    <div style={classes.container}>
      <Helmet>
        <title>{t(renderHelmetTranslationKey())}</title>
      </Helmet>

      <ACSnackbar
        id="password-set-retry-snackbar"
        text={t('CONFIRMATION-REQUEST-RECEIVED-LABEL')}
        onClose={() => setIsSnackbarVisible(false)}
        open={isSnackbarVisible}
      />

      <ACSnackbar
        id="password-set-error-snackbar"
        text={t('ERROR-SNACKBAR-TEXT')}
        onClose={() => setIsErrorSnackbarVisible(false)}
        open={isErrorSnackbarVisible}
        severity="error"
      />
      <PublicRegistration hideImage={isSM}>
        <Typography sx={classes.indicatorTitle}>
          {b2bPage
            ? 'Check your email for our verification code'
            : "Let's set up your account..."}
        </Typography>
        <Typography sx={classes.indicatorText}>
          {b2bPage
            ? "Then we can proceed to showing you the amazing caliber of professionals who'd want to be on your advisory board."
            : 'Check your inbox to get your verification code and set your password below'}
        </Typography>
        {renderScene()}
      </PublicRegistration>
    </div>
  ) : (
    <>
      <Helmet>
        <title>{t('PASSWORD_RESET_HEADER_TITLE')}</title>
      </Helmet>
      <OopsWindow />
    </>
  );
}

export default PasswordVerify;
