import React from 'react';
import { Box, Typography, Paper, Avatar } from '@mui/material';
import { ReactComponent as SpickerIcon } from '../../icons/speaker.svg';
import { useAccount } from '../../contexts/Account';
import { getAdvisorQueryParams } from '../../utils/queryParams';

const NewsAndEvents = () => {
  const { accountDetails, isFreeTrial, isFreemium } = useAccount();
  return (
    <Paper
      id="news-and-events-tile"
      elevation={1}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '15px',
        borderRadius: '12px',
        marginBottom: '20px',
        boxShadow: 'none',
      }}
    >
      <Avatar
        sx={{
          backgroundColor: '#7BC8FF',
          color: '#004170',
          marginRight: '16px',
          width: '60px',
          height: '60px',
          borderRadius: '8px',
        }}
      >
        <SpickerIcon fontSize="medium" />
      </Avatar>
      <Box>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: 16,
            marginBottom: '4px',
            color: '#001030',
          }}
        >
          News & Events
        </Typography>
        <Typography>
            <a
              id="newsAndEventsTour"
              style={{
                textDecoration: 'none',
                color: 'blue',
                cursor: 'pointer',
              }}>
                {"Take a tour "} 
            </a>
              to see what's new or visit our new 
            <a
              href={`https://advisorycloud.com/help?${getAdvisorQueryParams({
                accountDetails,
                isFreeTrial,
                isFreemium,
              })}`}
              style={{textDecoration: 'none', color: "blue"}} rel="noopener noreferrer">
                {" Videos & Tutorials "}
            </a>
            section to help with any of your questions.
        </Typography>

      </Box>
    </Paper>
  );
};

export default NewsAndEvents;
