import React from 'react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';

const AdvisoryBoardsHeader = (props) => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { peerPage } = props;

  return (
    <Box
      mb="16px"
      sx={{
        display: 'flex',
        flexDirection: isSM ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: isSM ? '20px' : '40px',
            fontWeight: 600,
            lineHeight: isSM ? '24px' : '52px',
            textAlign: 'left',
            color: '#001030',
            marginBottom: '12px',
          }}
        >
          {peerPage
            ? 'Experience the benefits and ROI of joining a peer advisory board.'
            : 'Welcome to the largest collection of advisory boards, available to join exclusively on AdvisoryCloud.'}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#4D586F',
          }}
        >
          {peerPage
            ? 'Immediately join one of our 9 types of peer advisory boards that have monthly meetings on current career opportunities, networking, compensation trends, and the sharing of best practices.'
            : "Join as many boards as you'd like, and take part in upcoming board meetings. Simply click any board that interests you to learn more and get started."}
        </Typography>

        {!peerPage && (
          <Button
            id="tour-boards"
            onClick={() => {}}
            disableRipple
            sx={{
            color: 'white',
            textTransform: 'none',
            fontSize: 14,
            fontWeight: '600',
            lineHeight: '18px',
            letterSpacing: '0.5px',
            mt: '16px',
            width: isXS ? '100%' : null,
            backgroundColor: '#3171F6',
            ':hover': {
              backgroundColor: '#3171F6',
            },
          }}
          >
            Which should I join?
          </Button>
        )}
      </Box>
      {peerPage && (
        <Box
          width={isSM ? '100%' : null}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: isSM ? '16px' : '0',
            ml: !isSM ? '75px' : null,
          }}
        >
          <img
            src="https://d9hhrg4mnvzow.cloudfront.net/register.advisorycloud.com/team-boards/b579d895-f455438f-image-15_1000000000000000000028.png"
            alt="Peer Advisory Boards"
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
      )}
    </Box>
  );
};

export default AdvisoryBoardsHeader;
