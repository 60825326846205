import React, { useEffect, useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Avatar,
  Stack,
  Chip,
  Box,
} from '@mui/material';
import LoadingLogoSkeleton from '../../../components/SkeletonLoading/loadingLogoSkeleton';
import { useAccount } from '../../../contexts/Account';
import { getAdvisorQueryParams } from '../../../utils/queryParams';
import { getAdvisorBasicInfo } from '../../../services/company_services';

export default function CurrentAdvisorsTile(props) {
  const { boardMembers, tileTitleStyle, tileDescriptionStyle } = props;
  const { accountDetails, currentPlan, isFreeTrial, isFreemium } = useAccount();
  const [advisors, setAdvisors] = useState([]);
  const [loadingAdvisors, setLoadingAdvisors] = useState(true);
  const tier = currentPlan?.salesforceValues?.tier;
  const palette = [
    'primary.main',
    'info.main',
    'error.main',
    'success.main',
    'warning.main',
  ];

  useEffect(async () => {
    setLoadingAdvisors(true);
    try {
      if (!boardMembers || boardMembers.length === 0) {
        return;
      }
      const promises = boardMembers.slice(0, 5).map((member) => {
        return getAdvisorBasicInfo({ ID: member.id });
      });
      const responses = await Promise.all(promises);
      setAdvisors(responses.map((response) => response.data?.getAdvisor));
    } catch (error) {
      console.log('error', error);
    }
    setLoadingAdvisors(false);
  }, [boardMembers]);

  return (
    <Card
      sx={{
        height: '100%',
        borderRadius: '12px',
        boxShadow: '0px 10px 12px 0px #0010300D',
      }}
    >
      <CardContent>
        <Typography sx={tileTitleStyle}>Current Advisors</Typography>
        <Typography sx={{ ...tileDescriptionStyle, mb: '10px' }}>
          Want to get featured and prioritized as a speaker at future board
          meetings?
        </Typography>
        {!['Executive', 'VIP'].includes(tier) && currentPlan.upgradeUrl && (
          <Typography
            onClick={() => {
              window.location.replace(
                `${currentPlan.upgradeUrl}?${getAdvisorQueryParams({
                  accountDetails,
                  isFreeTrial,
                  isFreemium,
                })}`
              );
            }}
            sx={{
              fontSize: '12px',
              fontWeight: 700,
              lineHeight: '18px',
              color: '#2B71F6',
              cursor: 'pointer',
            }}
          >
            Become a Featured Advisor today.
          </Typography>
        )}
        {loadingAdvisors ? (
          <Box
            width="100%"
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LoadingLogoSkeleton />
          </Box>
        ) : (
          <>
            <Stack direction="row" spacing={1} mt={1}>
              {advisors.map((advisor, index) => {
                const advisorImage =
                  advisor && advisor.image && advisor.image.imageKey
                    ? `${process.env.REACT_APP_IMAGES_URL}${advisor.image.imageKey}`
                    : null;
                return (
                  <Avatar
                    key={advisor.id}
                    alt={advisor.displayName}
                    src={advisorImage}
                    sx={{
                      width: '32px',
                      height: '32px',
                      bgcolor: palette[index % palette.length],
                      fontSize: '18px',
                      fontWeight: '500',
                    }}
                  >
                    {!advisorImage && advisor.displayName[0]}
                  </Avatar>
                );
              })}
            </Stack>
            {boardMembers.length > 5 && (
              <Chip
                label={`+${boardMembers.length - 5} more`}
                sx={{
                  mt: 2,
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#001030',
                  bgcolor: '#E8EAED',
                }}
              />
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
//
