import React, { useState } from 'react';
import { Menu, MenuItem, Button, useMediaQuery } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import {
  subscribeToGoogleCalendar,
  unsubscribeFromGoogleCalendar,
  subscribeToAppleCalendar,
  unsubscribeFromAppleCalendar,
} from '../../services/advisor_services';
import LoadingLogoSkeleton from '../SkeletonLoading/loadingLogoSkeleton';
import AppleLoginPopup from './AppleLoginPopup';
import { SnackbarType } from '../../services/utils/types';
import CustomIcon from '../../icons/customIcon';

const CalendarProviderButton = ({
  syncStates,
  setSyncStates,
  advisorId,
  companyIds,
  isLoading,
  setIsLoading,
  showSnackbar,
  isSinglePost,
}) => {
  const { googleSync, appleSync } = syncStates;
  const { setGoogleSync, setAppleSync } = setSyncStates;

  const [anchorEl, setAnchorEl] = useState(null);
  const [showApplePopup, setShowApplePopup] = useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const providers = [
    {
      key: 'google',
      name: googleSync ? 'Google Calendar' : 'Google Calendar (recommended)',
      sync: googleSync,
      setSync: setGoogleSync,
    },
    {
      key: 'apple',
      name: 'Apple Calendar',
      sync: appleSync,
      setSync: setAppleSync,
    },
  ];

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  // Inicializar Google Login
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const token = tokenResponse.code;
      const authCode = tokenResponse.code;
      try {
        setIsLoading(true);
        const response = await subscribeToGoogleCalendar({
          provider: 'google',
          token,
          authCode,
          advisorId,
          companyIds,
        });

        const responseMessage = response.data.subscribeToGoogleCalendar;

        if (responseMessage.includes('Success')) {
          setGoogleSync(true);
          console.log('Calendar successfully synced with Google.');
          showSnackbar('Google Calendar synced successfully.');
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (responseMessage.includes('Insufficient Permission')) {
          console.error('Insufficient Permission error.');
          showSnackbar(
            'Error: Please retry calendar sync to finalize.',
            SnackbarType.ERROR
          );
        } else {
          console.error('Error syncing with Google Calendar.');
          showSnackbar(
            'Error syncing with Google Calendar.',
            SnackbarType.ERROR
          );
        }
      } catch (error) {
        console.error('Error during Google Calendar subscription:', error);
        showSnackbar('Error syncing with Google Calendar.', SnackbarType.ERROR);
      } finally {
        setIsLoading(false);
      }
    },
    onError: () =>
      showSnackbar(
        'Error during Google Calendar subscription.',
        SnackbarType.ERROR
      ),
    scope:
      'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    access_type: 'offline',
    flow: 'auth-code',
  });

  const handleGoogleLogin = () => {
    googleLogin();
  };

  const handleAppleSubmit = async ({ email, password }) => {
    try {
      setIsLoading(true);
      const apiResponse = await subscribeToAppleCalendar({
        email,
        password,
        advisorId,
        companyIds,
      });

      const responseMessage = apiResponse.data.subscribeToAppleCalendar;

      if (responseMessage.includes('Success')) {
        setAppleSync(true);
        console.log('Calendar successfully synced with Apple.');
        showSnackbar('Calendar successfully synced with Apple.');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (responseMessage.includes('existing calendar')) {
        console.error('Invalid credentials provided.');
        showSnackbar(
          'Invalid credentials. Please try again.',
          SnackbarType.ERROR
        );
      } else {
        console.error('Error syncing with Apple Calendar:', responseMessage);
        showSnackbar('Error syncing with Apple Calendar.', SnackbarType.ERROR);
      }
    } catch (error) {
      console.error('Error during Apple Calendar subscription:', error);
      showSnackbar(
        'Error during Apple Calendar subscription.',
        SnackbarType.ERROR
      );
    } finally {
      setShowApplePopup(false);
      setIsLoading(false);
    }
  };

  const handleGoogleUnsubscribe = async (setGoogleSync) => {
    try {
      setIsLoading(true);
      const response = await unsubscribeFromGoogleCalendar({
        provider: 'google',
        advisorId,
        companyIds,
      });

      if (response.data.unsubscribeFromGoogleCalendar.includes('Success')) {
        setGoogleSync(false);
        console.log('Successfully unsubscribed from Google Calendar.');
        showSnackbar('Successfully unsubscribed from Google Calendar.');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        console.error('Error unsubscribing from Google Calendar.');
        showSnackbar(
          'Error unsubscribing from Google Calendar.',
          SnackbarType.ERROR
        );
      }
    } catch (error) {
      console.error('Error during Google Calendar unsubscription:', error);
      showSnackbar(
        'Error during Google Calendar unsubscription:',
        SnackbarType.ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAppleUnsubscribe = async (setAppleSync) => {
    try {
      setIsLoading(true);
      const response = await unsubscribeFromAppleCalendar({
        provider: 'apple',
        advisorId,
        companyIds,
      });

      if (response.data.unsubscribeFromAppleCalendar.includes('Success')) {
        setAppleSync(false);
        console.log('Successfully unsubscribed from Apple Calendar.');
        showSnackbar('Successfully unsubscribed from Apple Calendar.');
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        console.error('Error unsubscribing from Apple Calendar.');
        showSnackbar(
          'Error unsubscribing from Apple Calendar.',
          SnackbarType.ERROR
        );
      }
    } catch (error) {
      console.error('Error during Apple Calendar unsubscription:', error);
      showSnackbar(
        'Error during Apple Calendar unsubscription:',
        SnackbarType.ERROR
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSync = async (provider, setSync, syncState) => {
    setAnchorEl(null);
    if (syncState) {
      switch (provider) {
        case 'google':
          await handleGoogleUnsubscribe(setSync);
          break;
        case 'apple':
          await handleAppleUnsubscribe(setSync);
          break;
        default:
          console.log(`Unsubscribe for ${provider} not implemented.`);
      }
    } else {
      switch (provider) {
        case 'google':
          handleGoogleLogin(setSync);
          break;
        case 'apple':
          setShowApplePopup(true);

          break;
        default:
          console.log(`Login for ${provider} not implemented.`);
      }
    }
  };

  return (
    <>
      <Button
        id="Sync Calendar"
        onClick={handleOpenMenu}
        variant="contained"
        sx={{
          backgroundColor: isSinglePost ? '#6736FA' : '#3171F6',
          color: '#FFFFFF',
          fontSize: '16px',
          borderRadius: '999px',
          padding: '0',
          fontWeight: 700,
          marginTop: isSM ? '15px' : '0px',
          textTransform: 'none',
          width: isSM && !isSinglePost ? '350px' : '296px',
          height: '44px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          '&:hover': {
            backgroundColor: isSinglePost ? '#6736FA' : '#3171F6',
          },
          '&:disabled': {
            backgroundColor: '#3171F6',
            color: '#FFFFFF',
            opacity: 0.7,
          },
        }}
        disabled={isLoading}
      >
        {isSinglePost && !isLoading && (
          <CustomIcon
            iconname="calendar-meeting"
            style={{
              height: '100%',
              fontSize: '24px',
              marginRight: '10px',
            }}
          />
        )}

        {isLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingLogoSkeleton />
          </div>
        ) : googleSync || appleSync ? (
          'Manage Calendar Sync'
        ) : (
          'Sync Calendar'
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            marginTop: '10px',
          },
        }}
      >
        {providers.map((provider) => (
          <MenuItem
            key={provider.key}
            onClick={() =>
              handleSync(provider.key, provider.setSync, provider.sync)
            }
          >
            {provider.sync
              ? `Unsync from ${provider.name}`
              : `Sync with ${provider.name}`}
          </MenuItem>
        ))}
      </Menu>
      <AppleLoginPopup
        open={showApplePopup}
        onClose={() => setShowApplePopup(false)}
        onSubmit={handleAppleSubmit}
      />
    </>
  );
};

export default CalendarProviderButton;
