import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { searchCompanies } from '../../services/advisor_services';
import { useAccount } from '../../contexts/Account';
import AdvisorSearchCompanies from '../../components/Advisor/SearchCompanies';
import SceneContentStateSelector from '../../components/SceneContentStateSelector';
import {
  EndpointResponseObjectName,
  EndpointParameterFilters,
} from '../../services/utils/types';
import CompaniesFilter from '../../components/CompaniesFilter';
import AdvisorTopTiles from '../../components/TopTiles';
import { isNil } from '../../services/utils';

function getPadding(isSM, isV2Page) {
  if (isSM) return '10px';
  if (isV2Page) return '0px';
  return '20px';
}

const useStyles = (isSM, isV2Page) => ({
  container: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    maxWidth: '1200px',
    padding : `20px ${getPadding(isSM, isV2Page)}`,
    marginRight: isV2Page ? 'auto' : null,
    marginLeft: isV2Page ? 'auto' : null,
    width: isSM ? '100%' : null,
  },
});

function SearchCompanies() {
  const location = useLocation();
  const isV2Page = location.pathname.includes('/v2/');
  
  const isSM = useMediaQuery((theme) => {
    return location.pathname.includes('/board-directory')
      ? theme.breakpoints.down([1272])
      : theme.breakpoints.down('sm');
  });
  const lessThan440 = useMediaQuery((theme) => theme.breakpoints.down(440));
  const { asLoggedUserDetails } = useAccount();
  const [doNotFetch, setDoNotFetch] = useState(false);
  const classes = useStyles(isSM, isV2Page);

  const { t } = useTranslation();
  function getQueryParam() {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      return queryParams.get('keyword') || '';
    }
    return '';
  }

  function getQueryParamP2P() {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const filter = queryParams.get('p2p_boards');
      if (filter) {
        return filter === 'true';
      }
      const type = queryParams.get('type');
      const peerTypes = {
        peer: 'P2P',
        company: 'Company',
      };
      if (type && peerTypes[type]) {
        return peerTypes[type];
      }
    }
    return false;
  }

  const [searchP2PBoards, setSearchP2PBoards] = useState(getQueryParamP2P());
  const boardMeetingsPage = location.pathname === '/board-meetings';

  const [queryParameters, setQueryParameters] = useState({
    ADVISOR_ID: asLoggedUserDetails.userId,
    SEARCH_STRING: getQueryParam(),
    LIMIT: EndpointParameterFilters.COMPANIES_FETCH_LIMIT,
    OFFSET: 0,
    UPCOMING_BOARD_MEETINGS_ONLY: boardMeetingsPage,
    PEER_FILTER: searchP2PBoards,
  });

  useEffect(() => {
    const newSearchP2P = getQueryParamP2P();
    const newSearchString = getQueryParam();
    const newBoardMeetingsPage = location.pathname === '/board-meetings';
    setSearchP2PBoards(newSearchP2P);
    setQueryParameters((prev) => ({
      ...prev,
      SEARCH_STRING: newSearchString,
      PEER_FILTER: newSearchP2P,
      UPCOMING_BOARD_MEETINGS_ONLY: newBoardMeetingsPage,
    }));

    setDoNotFetch(false);
  }, [location.search, location.pathname]);

  function handleSearch(props) {
    const { keywords, skills, industries, setReverseP2P, type } = props;

    let peerFilterValue;
    if (type) {
      peerFilterValue = type.length === 2 ? 'Both' : type.value;
    } else {
      peerFilterValue = setReverseP2P ? !searchP2PBoards : searchP2PBoards;
    }

    const searchString = {
      ADVISOR_ID: asLoggedUserDetails.userId,
      LIMIT: EndpointParameterFilters.COMPANIES_FETCH_LIMIT,
      OFFSET: 0,
      PEER_FILTER: peerFilterValue,
    };

    if (keywords && keywords.length > 0) {
      searchString.SEARCH_STRING = keywords;
    }
    if (!isNil(skills) && skills[0]) {
      searchString.SKILLS_TAGS_IDS = skills.map((skill) => skill.id);
    }
    if (!isNil(industries) && industries[0]) {
      searchString.INDUSTRY_TAGS_IDS = industries.map((industry) => industry.id);
    }

    setDoNotFetch(false);
    setQueryParameters(searchString);
  }

  return (
    <div style={classes.container}>
      {!boardMeetingsPage && (
        <AdvisorTopTiles
          type="SEARCH"
          tileTitle="Experience the benefits of also joining a peer advisory board!"
          tileText={`Immediately join one of our 9 types of peer advisory boards that have monthly meetings${
            lessThan440
              ? '.'
              : ' on current career opportunities, networking, compensation trends, and the sharing of best practices.'
          }`}
          cardId="boardVideo"
        />
      )}
      {!boardMeetingsPage && (
        <>
          <CompaniesFilter
            onSearchButtonClicked={handleSearch}
            searchP2PBoards={searchP2PBoards}
          />

          <SceneContentStateSelector
            helmetTitle={t('BOARD_DIRECTORY_HELMET_TITLE')}
            endPointAppSyncName={EndpointResponseObjectName.SEARCH_COMPANIES}
            endPointCall={searchCompanies}
            endPointCallParameters={queryParameters}
            ContentTag={AdvisorSearchCompanies}
            doNotShowEmptyStateScene
            doNotFetch={doNotFetch}
            isBoardSekeleton
            contentProps={{
              emptySearchParams:
                !queryParameters.SEARCH_STRING &&
                (!queryParameters.SKILLS_TAGS_IDS ||
                  queryParameters.SKILLS_TAGS_IDS?.length === 0) &&
                (!queryParameters.INDUSTRY_TAGS_IDS ||
                  queryParameters.INDUSTRY_TAGS_IDS?.length === 0),
            }}
          />
        </>
      )}
    </div>
  );
}
export default SearchCompanies;
