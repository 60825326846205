import React, { useState } from 'react';
import AdvisoryCloudLogo from './../advisorComponents/AdvisoryCloudLogo';
import { useAccount } from '../contexts/Account';
import { Box, Avatar, Button, IconButton } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import CalendarSyncMenu from '../advisorComponents/CalendarSyncMenu';
import MobileMenu from './MobileMenu';
import DefaultAdvisorAvatar from './../icons/user-avatar.svg';
import ProfileMenu from './ProfileMenu';
import { getAdvisorQueryParams } from '../utils/queryParams';
import { SnackbarType } from '../services/utils/types';
import ACSnackbar from '../components/ACSnackbar';
import LoadingLogoSkeleton from '../components/SkeletonLoading/loadingLogoSkeleton';
import UpsellBanner from './UpsellBanner';
import HoverMenuItem from '../components/v2/HoverMenuItem';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';

const TopBar = ({ legacy }) => {
  const history = useHistory();
  const { accountDetails, signOut, isFreeTrial, isFreemium } = useAccount();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(SnackbarType.SUCCESS);
  const [googleSync, setGoogleSync] = useState(
    accountDetails?.calendarTokens?.google || false
  );
  const [appleSync, setAppleSync] = useState(
    accountDetails?.calendarTokens?.apple || false
  );
  const { pathname, search } = useLocation();
  const fullPath = `${pathname}${search}`;
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const showSnackbar = (message, severity = SnackbarType.SUCCESS) => {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setSnackbarOpen(true);
  };

  const redirectWithParams = (url) => {
    window.location.assign(
      `${url}?${getAdvisorQueryParams({
        accountDetails,
        isFreeTrial,
        isFreemium,
      })}`
    );
  };

  function navigateToPersonalAssistance() {
    redirectWithParams(
      'https://register.advisorycloud.com/platform/engagement-call/'
    );
  }

  function navigateInApp(path) {
    history.push(path);
  }

  const menuItems = [
    { label: 'Dashboard', to: '/v2/dashboard', id: 'menu-dashboard' },
    {
      label: 'Explore',
      to: '/explore',
      id: 'menu-explore',
      paths: [],
      options: [
        {
          label: "What's New",
          id: 'submenu-whats-new',
          onClick: () =>
            redirectWithParams('https://advisorycloud.com/whatsnew'),
        },
        {
          label: 'Take a Platform Tour',
          id: 'submenu-platform-tour',
          closeOnClick: true,
          onClick: () => {},
        },
        {
          label: 'Frequently Asked Questions',
          id: 'submenu-faq',
          onClick: () => redirectWithParams('https://advisorycloud.com/help'),
        },
        {
          label: 'ROI of Joining Boards',
          id: 'submenu-roi-of-joining-boards',
          onClick: () => redirectWithParams('https://advisorycloud.com/roi-jb'),
        },
        {
          label: 'Get Personal Assistance',
          id: 'submenu-get-personal-assistance',
          onClick: () =>
            redirectWithParams(
              'https://register.advisorycloud.com/platform/engagement-call'
            ),
        },
      ],
    },
    {
      label: 'Join Advisory Boards',
      to: '/v2/board-directory',
      id: 'menu-join-advisory-boards',
      containerWidth: '220px',
      leftOptionsPosition: 54,
      paths: ['/v2/board-directory', '/peer-directory'],
      mainOnClick: () => {
        history.push('/v2/board-directory');
      },
      options: [
        {
          label: 'Startups',
          id: 'submenu-startups',
          closeOnClick: true,
          onClick: () => {
            history.push('/v2/board-directory?keyword=startup');
          },
        },
        {
          label: 'Private Companies',
          id: 'submenu-private-companies',
          closeOnClick: true,
          onClick: () => {
            history.push('/v2/board-directory');
          },
        },
        {
          label: 'International Companies',
          id: 'submenu-international-companies',
          closeOnClick: true,
          onClick: () => {
            history.push('/v2/board-directory?keyword=international');
          },
        },
        {
          label: 'Non-Profits',
          id: 'submenu-non-profits',
          closeOnClick: true,
          onClick: () => {
            history.push('/v2/board-directory?keyword=non-profit');
          },
        },
        {
          label: 'Peer Advisory Boards',
          id: 'submenu-peer-advisory-boards',
          closeOnClick: true,
          onClick: () => {
            history.push(
              legacy ? '/v2/board-directory?type=peer' : '/peer-directory'
            );
          },
        },
      ],
    },
    {
      label: 'Build an Advisory Board',
      to: '',
      id: 'menu-build-advisory-board',
      containerWidth: '204px',
      leftOptionsPosition: 60,
      paths: [],
      options: [
        {
          label: 'For My Company',
          id: 'submenu-for-my-company',
          onClick: () =>
            navigateInApp(
              '/v2/personal-advisory-boards?source=company'
            ),
        },
        {
          label: 'For My Team',
          id: 'submenu-for-my-team',
          onClick: () =>
            navigateInApp(
              '/v2/personal-advisory-boards?source=team'
            ),
        },
        {
          label: 'For My Personal Project',
          id: 'submenu-for-my-personal-project',
          onClick: () =>
            navigateInApp(
              '/v2/personal-advisory-boards?source=personal'
            ),
        },
        {
          label: 'For My Career',
          id: 'submenu-for-my-',
          onClick: () =>
            navigateInApp(
              '/v2/personal-advisory-boards?source=career'
            ),
        },
      ],
    },
    {
      label: 'Networking',
      to: '',
      id: 'menu-networking',
      onClick: () =>
        redirectWithParams(
          'https://advisorycloud.com/networking-on-advisorycloud'
        ),
    },
  ];

  const menuItemStyle = {
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: '500',
    padding: '5px 18px',
    borderRadius: '8px',
  };

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleLogout = () => {
    handleProfileMenuClose();
    if (typeof signOut === 'function') {
      signOut();
    } else {
      console.log('signOut function is not provided');
    }
  };

  const handleOpenCalendarMenu = (event) => {
    setCalendarAnchorEl(event.currentTarget);
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1200,
        backgroundColor: '#fff',
      }}
    >
      <UpsellBanner />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: { xxs: '10px 3px 10px 5px', sm: '10px 25px' },
          backgroundColor: '#fff',
          borderBottom: '1px solid #ddd',
          height: 60,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AdvisoryCloudLogo
            height={'27px'}
            removeHyperlink
            onClick={() => {
              history.push(`/v2/dashboard`);
            }}
            cursor="default"
          />
        </Box>

        <Box
          sx={{
            display: 'none',
            '@media (min-width: 1069px)': {
              display: 'flex',
            },
            alignItems: 'center',
          }}
        >
          {menuItems.map((item, index) => {
            if (item.options) {
              return (
                <HoverMenuItem
                  key={index}
                  label={item.label}
                  id={item.id}
                  options={item.options}
                  paths={item.paths}
                  menuItemStyle={menuItemStyle}
                  closeOnClick={item.closeOnClick}
                  containerWidth={item.containerWidth}
                  leftOptionsPosition={item.leftOptionsPosition}
                  mainOnClick={item.mainOnClick}
                />
              );
            }
            const isActive = fullPath === item.to;
            if (item.onClick) {
              return (
                <Button
                  id={item.id}
                  key={index}
                  onClick={item.onClick}
                  sx={{
                    ...menuItemStyle,
                    color: isActive ? '#2B71F6' : '#2E3B56',
                    backgroundColor: isActive ? '#F4F8FF' : 'transparent',
                    cursor: 'pointer',
                    background: 'none',
                    border: 'none',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  {item.label}
                </Button>
              );
            }

            return (
              <NavLink
                id={item.id}
                key={index}
                to={item.to || '#'}
                style={{
                  ...menuItemStyle,
                  color: isActive ? '#2B71F6' : '#2E3B56',
                  backgroundColor: isActive ? '#F4F8FF' : 'transparent',
                }}
              >
                {item.label}
              </NavLink>
            );
          })}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          <IconButton
            sx={{ display: { xs: 'flex', md: 'none' }, color: '#2563eb' }}
            onClick={navigateToPersonalAssistance}
          >
            <PhoneInTalkOutlinedIcon />
          </IconButton>
          <Button
            variant="outlined"
            startIcon={<PhoneInTalkOutlinedIcon />}
            id="sync-calendar-button"
            disabled={isLoading}
            sx={{
              display: { xxs: 'none', xs: 'none', md: 'flex' },
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: '600',
              padding: '0',
              borderRadius: '4px',
              borderColor: '#2B71F6',
              color: '#2B71F6',
              width: 220,
              height: 32,
              '&:hover': {
                borderColor: '#1e3a8a',
                backgroundColor: '#f1f5ff',
              },
            }}
            onClick={navigateToPersonalAssistance}
          >
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <LoadingLogoSkeleton />
              </div>
            ) : "Personal Assistance"}
          </Button>
          <Avatar
            src={
              accountDetails.image?.imageKey
                ? `${process.env.REACT_APP_IMAGES_URL}${accountDetails.image.imageKey}`
                : DefaultAdvisorAvatar
            }
            alt={accountDetails.displayName}
            sx={{
              width: 32,
              height: 32,
              cursor: 'pointer',
              display: { xxs: 'none', md: 'block' },
            }}
            onClick={handleProfileMenuOpen}
          />

          <IconButton
            id="menu-icon-button"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: 'block', md: 'none' }, color: '#2563eb' }}
          >
            <MenuIcon />
          </IconButton>

          <MobileMenu
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            menuItems={menuItems}
            pathname={pathname}
          />
        </Box>

        <ProfileMenu
          profileAnchorEl={profileAnchorEl}
          handleProfileMenuClose={handleProfileMenuClose}
          handleLogout={handleLogout}
          accountDetails={accountDetails}
        />

        <CalendarSyncMenu
          anchorEl={calendarAnchorEl}
          setAnchorEl={setCalendarAnchorEl}
          syncStates={{
            googleSync,
            appleSync,
          }}
          setSyncStates={{
            setGoogleSync,
            setAppleSync,
          }}
          setIsLoading={setIsLoading}
          showSnackbar={showSnackbar}
        />
        <ACSnackbar
          style={{ marginTop: isFreemium ? '50px' : '0px' }}
          open={snackbarOpen}
          text={snackMessage}
          severity={snackSeverity}
          onClose={() => {
            setSnackbarOpen(false);
          }}
          autoHideDuration={5000}
        />
      </Box>
    </Box>
  );
};

export default TopBar;
