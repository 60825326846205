import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useAccount } from './../../../contexts/Account';
import PostResponseReply from './../../Post/ResponseReply';
import eventBus, { eventBusValues } from './../../../eventBus';
import { PostType, OnboardingStatus } from './../../../services/utils/types';
import CompanyEmptyState from './../../EmptyState/CompanyEmptyState';
import DashboardEmptyState from './dashboardEmptyState';
import InsightsEmptyState from './insightsEmptyState';
import MeetingsEmptyState from './meetingsEmptyState';

const useStyles = (isXS) => ({
  flexContainerRow: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: '5px',
    justifyContent: isXS ? 'space-between' : 'unset',
  },
  calendar: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '10px',
    marginBottom: '2px',
    borderRadius: '50%',
  },
  buttonSeparation: {
    marginRight: '10px',
  },
  header: {
    color: 'info.main',
    marginBottom: '20px',
    fontWeight: 'lighter',
  },
  text: {
    color: 'primary.main',
    marginBottom: '20px',
    fontWeight: 'lighter',
  },
  subtext: {
    color: 'info.light',
    marginBottom: '10px',
    fontWeight: 'lighter',
  },
  subtextFree: {
    color: 'info.main',
    marginBottom: '10px',
    fontWeight: 'lighter',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '15px',
  },
  textAroundButton: {
    display: 'inline',
    color: 'primary.main',
    fontWeight: 'lighter',
  },
  textButton: {
    color: 'primary.main',
    height: '0px',
    padding: '0px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline currentcolor',
    display: 'inline',
  },
  textButtonMetric: {
    fontSize: '0.7rem',
  },
  content: {
    color: 'primary.main',
    display: 'inline',
  },
  contentFree: {
    color: 'info.main',
    display: 'inline',
  },
  metricContainer: {
    marginRight: '20px',
    marginBottom: '20px',
  },
  mobileMetricContainer: {
    display: 'contents',
  },
  flexPromptContainer: {
    display: 'flex',
    marginBottom: '25px',
  },
  calendarContainer: {
    width: '40px',
  },
  textContainer: {
    textAlign: 'left',
    width: '100%',
  },
  addMargin: {
    marginBottom: '140px',
  },
  remove: {
    display: 'none',
  },
});

function DashboardCompanyContent(props) {
  const {
    showPostPopUp,
    showPostWrapperContent,
    insightCount,
    postsList,
    isMainCallFetching,
    incrementOffset,
    addPostLike,
    filterBy,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isXS);
  const { accountDetails, type, isFreeTrial, currentUserInfo } = useAccount();

  // Advisor Count Metric
  const advisorCount = accountDetails.boardAdvisors
    ? accountDetails.boardAdvisors.filter((x) => x.advisorStatus == 'active')
        .length
    : 0;
  const [openPostPopUp, setOpenPostPopUp] = useState(false);
  const [postType, setPostType] = useState('');
  const [singlePostMode, setSinglePostMode] = useState(false);
  const callNotRequiredValuesArray = [
    OnboardingStatus.SCHEDULED,
    OnboardingStatus.COMPLETE,
    OnboardingStatus.NOT_REQUISITE,
  ];

  function handleClickQuestion() {
    setPostType(PostType.QUESTION);
    setOpenPostPopUp(!openPostPopUp);
  }

  function handleClickUpdate() {
    setPostType(PostType.UPDATE);
    setOpenPostPopUp(!openPostPopUp);
  }

  function SinglePostChangedCallback(isSinglePost) {
    setSinglePostMode(isSinglePost);
  }

  function goToSearchAdvisors() {
    history.push('advisors/search');
  }

  function gotToMyAdvisors() {
    history.push('advisors');
  }

  function gotToCompanyOnboardingCall() {
    if (isFreeTrial) {
      eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
    } else {
      let CALL_URL_PARAMS = `
      name=${encodeURIComponent(
        `${accountDetails.userData.givenName} ${accountDetails.userData.surName}`
      )}&
      email=${encodeURIComponent(accountDetails.userData.email)}&
      company=${encodeURIComponent(accountDetails.companyName)}&
      user=${encodeURIComponent(accountDetails.userData.id)}&
      account-type=${type}&
      workflow=signup
    `;

      if (
        accountDetails.userData.phoneNumber &&
        accountDetails.userData.phoneNumber.internationalPhoneNumber
      ) {
        CALL_URL_PARAMS = `${CALL_URL_PARAMS}&mobile=${encodeURIComponent(
          accountDetails.userData.phoneNumber.internationalPhoneNumber.replace(
            '+',
            ''
          )
        )}`;
      }

      history.push({
        pathname: '/company/onboarding/call',
        search: `?${CALL_URL_PARAMS.replace(/\s/g, '')}`,
      });
    }
  }

  return (
    <>
      <PostResponseReply
        gotPostList={postsList}
        hideTopCards
        onSinglePostChanged={SinglePostChangedCallback}
        multiplePosts
        isMainCallLoading={isMainCallFetching}
        incrementOffset={incrementOffset}
        addPostLike={addPostLike}
        dashboardOrigin
      />

      {postsList.length === 0 &&
        (filterBy.includes('meeting') ? (
          <MeetingsEmptyState />
        ) : filterBy.includes('insight') ? (
          <InsightsEmptyState />
        ) : (
          <DashboardEmptyState />
        ))}
    </>
  );
}

export default DashboardCompanyContent;
