import React from 'react';
import { Box, Typography, Grid, useMediaQuery } from '@mui/material';
import NewsAndEvents from './NewsAndEvents';
import Invitations from './Invitations';
import MyBoards from './MyBoards';
import UpcomingBoardMeetings from './UpcomingBoardMeetings';
import { useAccount } from '../../contexts/Account';

const Dashboard = () => {
  const { accountDetails } = useAccount();
  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isWrapped = useMediaQuery((theme) => theme.breakpoints.down([1302]));

  return (
    <Box
      sx={{
        padding: { xxs: '12px 15px', sm: '24px 30px' },
        width: isSM ? '100%' : null,
      }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            width: isSM ? '100%' : null,
          }}
        >
          <Box sx={{ marginBottom: '20px' }}>
            <Typography
              sx={{ fontWeight: '600', fontSize: { xxs: 32, sm: 40, lineHeight: isWrapped  ? "25px" : "inherit"}}}
            >
              Welcome,{' '}
              <span style={{ color: '#2C71F6' }}>
                {accountDetails.givenName}.&nbsp;
              </span>
              {!isXs ? (
                <span
                  style={{
                    fontWeight: '400',
                    color: '#4D586E',
                    fontSize: 20,
                  }}
                >
                  Here's your dashboard for your boards.
                </span>
              ) : null}
            </Typography>
            {isXs && (
              <Typography
                sx={{ fontWeight: '400', color: '#4D586E', fontSize: 16 }}
              >
                Here's your dashboard for your boards.
              </Typography>
            )}
          </Box>
          <NewsAndEvents />
          <Invitations />
          <MyBoards />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            width: isSM ? '100%' : null,
          }}
        >
          <UpcomingBoardMeetings />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
